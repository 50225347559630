import React, { FC, useRef, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Box, LinearProgress } from '@mui/material';

import { IAwards } from 'pages/main/awards/consts';

import AwardsCard from '../AwardsCard';

interface IProps {
  awards: IAwards[];
}

const AwardsCarousel: FC<IProps> = ({ awards }) => {
  const [progress, setProgress] = useState<number>(0);
  const swiperRef = useRef<any>(null);
  const handleSlideChange = () => {
    setProgress((swiperRef?.current?.swiper?.realIndex / (awards.length - 1)) * 100);
  };
  return (
    <Box sx={{ maxWidth: '1070px', width: '100%' }}>
      <Swiper
        modules={[Autoplay]}
        ref={swiperRef}
        speed={1000}
        loop={true}
        autoplay={{ delay: 3000 }}
        direction="horizontal"
        slidesPerView="auto"
        onSlideChange={handleSlideChange}
      >
        {awards.map((award) => (
          <SwiperSlide
            style={{
              width: '266px',
              cursor: 'grab',
            }}
            key={award.id}
          >
            <AwardsCard award={award} />
          </SwiperSlide>
        ))}
      </Swiper>
      <LinearProgress
        sx={{
          mt: '32px',
          height: '6px',
          border: '1px solid #eacaa9',
          backgroundColor: 'rgba(234,202,169,0.28)',
          borderRadius: '20px',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#FF7A01',
            borderRadius: '20px',
          },
        }}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
};

export default AwardsCarousel;
