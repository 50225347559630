import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Divider, Link as MuiLink, Typography } from '@mui/material';

import { policies, popupBurgerLinks } from 'components/common/AppBar/consts';
import {social} from '../consts'

import { ReactComponent as Up } from 'assets/common/Up.svg';
import { ReactComponent as Logo } from 'assets/common/logo.svg';

const FooterMV = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        p: '50px 16px',
        boxSizing: 'border-box',
        gap: '24px',
        backgroundColor: theme.palette.black.main,
        position: 'relative',
      }}
    >
      <Up
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'auto' });
        }}
        style={{
          position: 'absolute',
          right: '16px',
          bottom: '122px',
          cursor: 'pointer',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '32px',
        }}
      >
        <Logo
          style={{ cursor: 'pointer', marginBottom: '16px' }}
          onClick={() => {
            navigate('');
          }}
        />
        {popupBurgerLinks.map((item) => (
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
              textAlign: 'left',
              cursor: 'pointer',
            }}
            key={item.link}
            onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              if (currentPath === '/') {
                scrollHandler(e, item.link);
                await new Promise((resolve) => setTimeout(resolve, 50));
              } else {
                navigate('/#' + item.link);
              }
            }}
          >
            {item.title}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          mt: '8px',
        }}
      >
        {social.map((item) => (
          <MuiLink key={item.link} href={item.link} underline="none" target="_blank">
            <Box component="img" alt="yotube" src={item.icon} />
          </MuiLink>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'start',
        }}
      >
        {policies.map((item) => (
          <Typography
            onClick={() => {
              navigate(`${item.link}`);
            }}
            key={item.link}
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '21px',
              textDecoration: 'none',
              color: theme.palette.text.primary,
              position: 'relative',
              cursor: 'pointer',
            }}
          >
            {item.title}
          </Typography>
        ))}
      </Box>
      <Divider
        sx={{
          width: '100%',
          borderColor: theme.palette.text.primary,
        }}
      />
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        © 2024 WecandevelopIT. All rights reserved.
      </Typography>
    </Box>
  );
};

export default FooterMV;
