import React, { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { FirstInfo } from 'pages/main/first/consts';

interface IProps {
  firstInfo: FirstInfo;
}

const FirstCard: FC<IProps> = ({ firstInfo }) => {
  return (
    <Box
      sx={{
        width: { xs: '155px', md: '263px' },
        height: { xs: '84px', md: '122px' },
        backdropFilter: 'blur(20px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF33',
        borderRadius: '20px',
      }}
    >
      {firstInfo.image ? (
        <Box
          component="img"
          src={firstInfo.image}
          alt="image"
          sx={{ width: { xs: '112px', md: '178px' }, height: 'auto' }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '32px', md: '48px' },
              fontWeight: 800,
              lineHeight: { xs: '43.71px', md: '66px' },
              textAlign: 'center',
              color: theme.palette.text.primary,
            }}
          >
            {firstInfo.number}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '16px' },
              fontWeight: 500,
              lineHeight: { xs: '24px', md: '24px' },
              textAlign: 'left',
              color: theme.palette.text.primary,
            }}
          >
            {firstInfo.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FirstCard;
