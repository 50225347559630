import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

import { ReactComponent as LogoBlack } from 'assets/common/LogoBlack.svg';
import { ReactComponent as MenuIcon } from 'assets/common/MenuIcon.svg';
import { ReactComponent as Logo } from 'assets/common/logo.svg';

import { CustomButton } from '../Button';
import { useGotAProject } from '../GotAProject/GotAProjectContext';
import DropDownMenu from './DropDownMenu';
import { PopupBurger } from './PopupBurger';
import { useSectionContext } from './SectionContext';
import { appbarLinks } from './consts';
import { logPageView } from 'utils/googleAnalytics';

const CustomAppBar = () => {
  const { activeSection, setActiveSection } = useSectionContext();
  const navigate = useNavigate();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  const [showAppBar, setShowAppBar] = useState(true);
  const lastScrollY = useRef(window.scrollY);
  const [isBackground, setIsBackgroundVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpenNavDrawer, setIsOpenNavDrawer] = useState<boolean>(false);
  const location = useLocation();
  const pathname = location.pathname;
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
      setShowAppBar(false);
      setAnchorEl(null);
    } else {
      setShowAppBar(true);
    }
    lastScrollY.current = currentScrollY;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    if (scrollTop < 1) {
      setIsBackgroundVisible(false);
    } else {
      setIsBackgroundVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    logPageView(pathname);
    if (['cookies', 'terms', 'privacy'].includes(pathname.split('/')[1])) {
      setActiveSection(null);
    }
    (async () => {
      setIsOpenGotAProjectDrawer(false);
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          history.replaceState(null, '', window.location.pathname + window.location.search);
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          await new Promise((resolve) => setTimeout(resolve, 500));
          setShowAppBar(false);
        }
      }
    })();
  }, [location]);
  const isReverseColor =
    ['news', 'cookies', 'terms', 'privacy'].includes(pathname.split('/')[1]) && !isBackground;
  return (
    <AppBar
      sx={{
        position: 'fixed',
        transition: 'top 0.4s ease, background 0.5s ease',
        top: showAppBar ? '0' : '-100px',
        background: isBackground ? theme.palette.black.main : 'transparent',
        boxShadow: 'none',
        px: '16px',
      }}
    >
      <Box
        sx={{
          maxWidth: '1290px',
          width: '100%',
          height: '100px',
          margin: 'auto',
          boxSizing: 'border-box',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '131px',
              md: '189px',
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          {isReverseColor ? (
            <LogoBlack
              style={{ cursor: 'pointer', width: '100%' }}
              onClick={() => {
                navigate('/');
              }}
            />
          ) : (
            <Logo
              style={{ cursor: 'pointer', width: '100%' }}
              onClick={() => {
                navigate('/');
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            width: '495px',
            height: '56px',
            backdropFilter: 'blur(20px)',
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: '16px',
            backgroundColor: '#FFFFFF33',
            border: `1px solid ${!isReverseColor ? '#FFFFFF80' : theme.palette.text.secondary}`,
            borderRadius: '30px',
          }}
        >
          {appbarLinks.map((item) => (
            <Typography
              key={item.text}
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                textAlign: 'center',
                cursor: 'pointer',
                color:
                  (item.link === 'process' &&
                    activeSection &&
                    ['process', 'howdowedo'].includes(activeSection)) ||
                  (item.link === 'portfolio' && pathname === '/portfolio')
                    ? theme.palette.orange.main
                    : item.link === activeSection
                      ? theme.palette.orange.main
                      : !isReverseColor
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
              }}
              onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                // setActiveSection(item.link);
                if (pathname === '/') {
                  scrollHandler(e, item.link);
                  await new Promise((resolve) => setTimeout(resolve, 50));
                  setShowAppBar(false);
                } else {
                  pathname === '/portfolio' && item.link === 'portfolio'
                    ? window.scrollTo({ top: 0 })
                    : navigate(`/#${item.link}`);
                }
              }}
            >
              {item.text}
            </Typography>
          ))}

          <DropDownMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setShowAppBar={setShowAppBar}
            activeSection={activeSection}
            isBackground={isBackground}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomButton
            sx={{
              width: { xs: '105px', md: '144px' },
              height: { xs: '37px', md: '56px' },
              borderRadius: '30px',
              backgroundColor: !isReverseColor
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
              color: !isReverseColor ? theme.palette.text.secondary : theme.palette.text.primary,
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: 500,
              lineHeight: { xs: '16px', md: '24px' },
            }}
            onClick={() => setIsOpenGotAProjectDrawer(true)}
          >
            Contact us
          </CustomButton>
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              cursor: 'pointer',
            }}
          >
            <MenuIcon
              stroke={isReverseColor ? theme.palette.black.main : theme.palette.text.primary}
              onClick={() => setIsOpenNavDrawer(true)}
            />
          </Box>

          <PopupBurger
            setShowAppBar={setShowAppBar}
            setIsOpenNavDrawer={setIsOpenNavDrawer}
            isOpenNavDrawer={isOpenNavDrawer}
            activeSection={activeSection}
            pathname={pathname}
          />
        </Box>
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;
