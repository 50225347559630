import { Terms } from 'components/common/TermsTemplate';


export const cookiesPolicy: Terms = {
  title: 'We Can Develop IT LLC Cookie Policy',
  createdAt: 'Last Updated: 1 May 2024',
  description:
    'This Cookie Policy explains how We Can Develop IT LLC ("We," "Us," "Our") uses cookies and similar tracking technologies on our website ("Website"). By using our Website, you agree to the use of cookies as outlined in this policy.',
  content: [
    { title: '1. What are Cookies?' },
    {
      description: [
        'Cookies are small text files that are placed on your device when you visit websites. They are widely used to make websites work more efficiently, as well as to provide information to the owners of the website.',
      ],
    },
    { title: '2. Types of Cookies We Use' },
    {
      description: ['We use the following types of cookies on our Website:'],
    },
    { descriptionItems: ['Essential Cookies:'] },
    {
      descriptionItemsWithoutDot: [
        'These cookies are necessary for the operation of our Website. They include, for example, cookies that enable you to log into secure areas of our Website and use its core features.',
      ],
    },
    { descriptionItems: ['Analytical/Performance Cookies:'] },
    {
      descriptionItemsWithoutDot: [
        'These cookies help us understand how visitors use our Website. They allow us to recognize and count the number of visitors and see how visitors move around the Website. This helps us to improve the way our Website works, ensuring that users find what they are looking for easily.',
      ],
    },
    { descriptionItems: ['Functional Cookies:'] },
    {
      descriptionItemsWithoutDot: [
        'These cookies are used to recognize you when you return to our Website. They allow us to personalize content for you, remember your preferences (such as your choice of language or region), and improve your overall experience.',
      ],
    },
    { descriptionItems: ['Targeting Cookies:'] },
    {
      descriptionItemsWithoutDot: [
        'These cookies record your visit to our Website, the pages you visited, and the links you followed. We use this information to make the Website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for the same purpose.',
      ],
    },
    { title: '3. How We Use Cookies' },
    {
      description: ['We use cookies to:'],
    },
    {
      descriptionItems: [
        'Provide and enhance our services.',
        'Understand and improve the performance and usability of our Website.',
        'Personalize your experience by remembering your preferences.',
        'Deliver relevant advertising and track its effectiveness.',
        'Maintain the security and integrity of our Website.',
      ],
    },
    { title: '4. Third-Party Cookies' },
    {
      description: [
        'In addition to our own cookies, we may also use third-party cookies from services like Google Analytics to collect usage statistics and deliver advertisements. These third-party cookies are governed by the privacy policies of the respective third parties.',
      ],
    },
    { title: '5. Managing Cookies' },
    {
      description: [
        'You can manage or delete cookies through your browser settings. Here’s how you can do it for some popular browsers:',
      ],
    },
    {
      descriptionItems: ['Google Chrome:'],
    },
    {
      descriptionItemslink: {
        title: 'Chrome Cookie Settings',
        link: 'https://support.google.com/chrome/answer/95647?hl=en',
      },
    },
    {
      descriptionItems: ['Mozilla Firefox:'],
    },
    {
      descriptionItemslink: {
        title: 'Firefox Cookie Settings',
        link: 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US',
      },
    },
    {
      descriptionItems: ['Safari:'],
    },
    {
      descriptionItemslink: {
        title: 'Safari Cookie Settings',
        link: 'https://support.apple.com/en-gb/guide/safari/sfri11471/mac',
      },
    },
    {
      descriptionItems: ['Microsoft Edge:'],
    },
    {
      descriptionItemslink: {
        title: 'Edge Cookie Settings',
        link: 'https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d',
      },
    },
    {
      description: [
        'Please note that blocking cookies may affect the functionality of our Website, and some features may not work as intended if cookies are disabled.',
      ],
    },
    { title: '6. Changes to This Cookie Policy' },
    {
      description: [
        'We may update this Cookie Policy from time to time. Any changes will be posted on this page, and we encourage you to review this Cookie Policy periodically. Your continued use of our Website after any changes signifies your acceptance of the new Cookie Policy.',
      ],
    },
    { title: '7. Contact Us' },
    {
      description: [
        'If you have any questions or concerns about this Cookie Policy, please contact us at ',
      ],
    },
    {
      link: {
        title: 'business@wecandevelopit.com',
        link: 'business@wecandevelopit.com',
      },

    },
  ],
};
