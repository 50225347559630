import React, { FC } from 'react';
import { Testimonials } from 'types';

import StarIcon from '@mui/icons-material/Star';
import { Box, Rating, Typography, useTheme, Link as MuiLink } from '@mui/material';

import { ReactComponent as BottomQuotes } from 'assets/main/testimonials/BottomQuotes.svg';
import { ReactComponent as LnIcon } from 'assets/main/testimonials/LnIcon.svg';
import { ReactComponent as TopQuotes } from 'assets/main/testimonials/TopQuotes.svg';

interface IProps {
  testimonials: Testimonials;
}

const TestimonialsCard: FC<IProps> = ({ testimonials }) => {
  const { avatar, name, job, bodyFirst, bodySecond, position, rating, createdAt, linkedin } =
    testimonials;
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: { xs: '343px', md: '520px' },
        height: { xs: '524px', md: '529px' },
        borderRadius: '20px',
        border: '1px solid #BCBCBC',
        p: { xs: '16px', md: '24px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              alignItems: { xs: 'end', md: 'center' },
              justifyContent: 'space-between',
              gap: '8px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                width: { xs: '300px', md: '394px' },
                minHeight: { xs: '132px', md: '124px' },
              }}
            >
              <Box
                component="img"
                src={avatar}
                alt="avatar"
                sx={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'start',
                  alignItems: 'left',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'start',
                    alignItems: 'left',
                    gap: '4px',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '20px', md: '24px' },
                      fontWeight: 700,
                      lineHeight: { xs: '30px', md: '36px' },
                      textAlign: 'left',
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '18px',
                      textAlign: 'left',
                    }}
                  >
                    {job}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '18px',
                      textAlign: 'left',
                    }}
                  >
                    {position}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'space-between', md: 'start' },
                    gap: '8px',
                  }}
                >
                  <Rating
                    name="text-feedback"
                    value={rating}
                    readOnly
                    precision={0.5}
                    sx={{ color: '#FF7A01', fontSize: { xs: '16px', md: '24px' } }}
                    icon={<StarIcon fontSize="inherit" />}
                    emptyIcon={<StarIcon style={{ opacity: 1 }} />}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      textAlign: 'left',
                      color: theme.palette.grey[600],
                    }}
                  >
                    {createdAt}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: { xs: '0px', md: '29px' }, width: { xs: '35px', md: '43px' } }}>
              <TopQuotes
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '32px' },
                fontWeight: 700,
                lineHeight: { xs: '24px', md: '38.4px' },
                textAlign: 'left',
                fontFamily: 'Caveat, Arial, sans-serif',
              }}
            >
              {bodyFirst}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                textAlign: 'left',
              }}
            >
              {bodySecond}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '35px', md: '43px' } }}>
          <BottomQuotes
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <MuiLink
          href={linkedin}
          underline="none"
          target="_blank"
          sx={{ width: { xs: '35px', md: '43px' } }}
        >
          <LnIcon
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </MuiLink>
      </Box>
    </Box>
  );
};

export default TestimonialsCard;
