import useJsonRepeatPlay from 'hooks/useJsonRepeatPlay';
import Lottie from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { IAwards } from 'pages/main/awards/consts';

interface IProps {
  award: IAwards;
}

const loadJson = (path: string) => {
  return import(`assets/main/awards/${path}`);
};
const AwardsCard: FC<IProps> = ({ award }) => {
  const {
    title,
    description,
    image,
    json,
    imageBoxSX,
    textBoxSX,
    mainBoxSX,
    jsonStyle,
    textSX,
    textTitleSX,
    imageGifStatic,
  } = award;
  const { animationData, isPlay, setIsPlay, setHasPlayed, handleMouseEnter } = useJsonRepeatPlay({
    json,
    loadJson,
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [isGifPlay, setIsGifPlay] = useState(false);
  useEffect(() => {
    if (inView) {
      handleMouseEnter();
    }
  }, [inView]);
  return (
    <Box
      onMouseEnter={() => {
        json && handleMouseEnter();
        setIsGifPlay(true);
      }}
      onMouseLeave={() => {
        json && setIsPlay(false);
        json && setHasPlayed(false);
        setIsGifPlay(false);
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: { xs: '250px', md: '300px' },
        width: '100%',
        height: { xs: '308px', md: '374px' },
        overflow: 'hidden',
        position: 'relative',
        p: { xs: '16px', md: '24px' },
        boxSizing: 'border-box',
        borderRadius: '20px',
        backgroundColor: '#1C1C1C',
        border: '1px solid #FFFFFF1A',
        ...mainBoxSX,
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <Box
        sx={{
          color: theme.palette.text.primary,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          ...textBoxSX,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '20px', md: '32px' },
            fontWeight: 700,
            lineHeight: { xs: '30px', md: '48px' },
            textAlign: 'left',
            position: 'relative',
            zIndex: 10,
            ...textTitleSX,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            position: 'relative',
            zIndex: 10,
            ...textSX,
          }}
        >
          {description}
        </Typography>
      </Box>
      {image && (
        <Box
          component="img"
          src={imageGifStatic ? (isGifPlay ? image : imageGifStatic) : image}
          sx={imageBoxSX}
        />
      )}
      {json && (
        <Box ref={ref} sx={{ ...jsonStyle }}>
          <Lottie
            style={{ zIndex: 1 }}
            animationData={animationData}
            autoplay={true}
            loop={isPlay}
            onComplete={() => {
              setHasPlayed(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AwardsCard;
