import { ChangeEventHandler, FC, HTMLInputTypeAttribute } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

interface TextInputProps extends StandardTextFieldProps {
  control: any;
  name: string;
  label: string;
  errors?: any;
  autoFocus?: boolean;
  onBlurHandler?: () => void;
  onChangeHandler?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  size?: 'medium' | 'small';
  type?: HTMLInputTypeAttribute;
}

const TextInputControlled: FC<TextInputProps> = ({
  control,
  errors,
  name,
  label,
  autoFocus,
  onBlurHandler,
  disabled,
  size = 'medium',
  type,
  sx,
  onChangeHandler,
  multiline,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name, ref, onBlur } }) => (
        <TextField
          onChange={onChangeHandler ?? onChange}
          value={value ?? ''}
          name={name}
          inputRef={ref}
          label={label}
          onBlur={onBlurHandler ?? onBlur}
          autoFocus={autoFocus}
          error={typeof errors === 'string' ? !!errors : !!errors?.[name]}
          helperText={typeof errors === 'string' ? errors : errors?.[name]?.message}
          size={size}
          disabled={disabled}
          type={type}
          fullWidth
          multiline={multiline}
          variant="filled"
          sx={{
            '&.Mui-focused': {
              color: theme.palette.text.disabled,
            },
            '& .MuiFilledInput-root': {
              backgroundColor: 'transparent',
            },
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: theme.palette.text.primary,
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: theme.palette.text.primary,
            },
            '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: '#d0cdcd',
            },
            ...sx,
          }}
          slotProps={{
            inputLabel: {
              shrink: false,
              sx: {
                color: theme.palette.text.disabled,
                display: value ? 'none' : 'block',
                top: '10px',
              },
            },
            input: {
              sx: {
                color: theme.palette.text.primary,
                minHeight: '50px',
                width: '100%',
                pb: '-5px',
              },
            },
          }}
          {...rest}
        />
      )}
    />
  );
};

export default TextInputControlled;
