import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { theme } from 'theme';

import styles from './styles.module.scss';

import { Box, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import AwardsCard from 'components/main/awards/AwardsCard';
import AwardsCarousel from 'components/main/awards/AwardsCarousel';

import { ReactComponent as Awards } from 'assets/main/awards/Awards.svg';
import Cup from 'assets/main/awards/Cup.webp';
import { ReactComponent as TUpper } from 'assets/main/awards/TUpper.svg';
import { ReactComponent as D } from 'assets/main/awards/d.svg';
import { ReactComponent as E } from 'assets/main/awards/e.svg';
import { ReactComponent as N } from 'assets/main/awards/n.svg';
import { ReactComponent as R } from 'assets/main/awards/r.svg';
import { ReactComponent as S } from 'assets/main/awards/s.svg';

import { awards } from './consts';

const AwardsSection = () => {
  const { mdXl, xlUp, smMD } = useMediaSize();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  return (
    <Box
      id="awards"
      className="awards_section"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.black.main,
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: { xs: '375px', md: '2007px' },
          p: { xs: '100px 16px', md: '140px 24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            maxWidth: '1750px',
            width: '100%',
            top: '140px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10,
          }}
        >
          <FlyingBall
            clsName="awards_section_flying_ball"
            setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
            bgClr={theme.palette.orange.main}
            sx={{ right: '-20px', top: '-100px' }}
          />
        </Box>
        <Box
          component="img"
          src={Cup}
          sx={{
            position: 'absolute',
            bottom: '650px',
            right: '-92px',
            zIndex: 0,
            display: { xs: 'none', md: 'block' },
          }}
        />
        <Awards
          style={{
            position: 'absolute',
            top: '45px',
            left: '3px',
            maxWidth: '1722px',
            width: '100%',
            height: 'auto',
          }}
        />
        <Box
          sx={{
            alignItems: 'end',
            position: 'absolute',
            bottom: '57.68px',
            left: '17px',
            maxWidth: '1722px',
            width: '100%',
            height: '200px',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <TUpper style={{ marginRight: '6.79px' }} />
          <R style={{ marginRight: '12.55px' }} />
          <E style={{ marginRight: '21.31px', zIndex: 3 }} />
          <N style={{ marginRight: '23.13px' }} />
          <D style={{ marginRight: '22.38px' }} />
          <S style={{ marginRight: '82px' }} />
        </Box>

        <Box
          className="awards_section_inner"
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              mb: '54px',
              color: theme.palette.text.primary,
            }}
          >
            Awards and Trends
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', md: 'grid' },
            }}
            className={
              xlUp
                ? styles.gridWrapperXL
                : mdXl
                  ? styles.gridWrapperSMXL
                  : smMD
                    ? styles.gridWrapperSM
                    : styles.gridWrapperXS
            }
          >
            {awards.map((award) => (
              <Box
                key={award.id}
                className={`${styles[`awards_card_${award.id}`]} ${styles.gridItem} awards_card_${award.id} awards_card`}
              >
                <AwardsCard award={award} />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <AwardsCarousel awards={awards} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AwardsSection;
