import Lottie from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { Portfolio } from 'types';
import { preloadImages } from 'utils/preloadingImages';

import { Box, Typography } from '@mui/material';

import preloader from 'assets/main/services/popup/preloader.json';

interface IProps {
  portfolio: Portfolio;
}

const PortfolioPageCard: FC<IProps> = ({ portfolio }) => {
  const { title, description, image } = portfolio;
  const [shouldRenderImage, setShouldRenderImage] = useState(false);
  useEffect(() => {
    (async () => {
      await preloadImages([image]);
      setShouldRenderImage(true);
    })();
  });
  return (
    <Box
      sx={{
        minHeight: { xs: '481px', md: '668px' },
        width: { xs: '343px', md: '630px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          width: { xs: '343px', md: '630px' },
          height: { xs: '300px', md: '554px' },
          borderRadius: '20px',
          overflow: 'hidden',
          border: '1px solid #FFFFFF80',
          position: 'relative',
        }}
      >
        {!shouldRenderImage ? (
          <Lottie
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            animationData={preloader}
            autoplay={true}
            loop={true}
          />
        ) : (
          <Box
            component="img"
            src={image}
            alt="image"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '346px', md: '635px' },
              height: 'auto',
              backgroundColor: '#1B1B1B',
              transition: '0.5s ease',
              '&:hover': {
                transform: `translate(-50%, -50%) scale(1.05)`,
                zIndex: 11,
                transition: '0.5s ease',
              },
            }}
          />
        )}
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: '20px', md: '24px' },
            fontWeight: 700,
            lineHeight: { xs: '30px', md: '36px' },
            textAlign: 'left',
            color: theme.palette.text.primary,
            mb: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 400,
            lineHeight: { xs: '21px', md: '24px' },
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PortfolioPageCard;
