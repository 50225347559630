import gsap from 'gsap';
import { boxHeight } from 'utils/boxHeight';

export const newsAnimation = (setActiveSection: (id: string | null) => void) => {
  gsap.set('.news_card_img', {
    xPercent: -100,
  });
  document.querySelectorAll('.news_card_img').forEach((el) => {
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      duration: 0.4,
    });
  });

  const tlBall = gsap.timeline({
    scrollTrigger: {
      trigger: '.news_section',
      start: 'top center',
      end: 'bottom center',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('news');
      },
      onEnterBack: () => {
        setActiveSection('news');
      },
      onLeave: () => {
        setActiveSection(null);
      },
    },
  });
  tlBall.fromTo(
    '.news_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      duration: 1,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    }
  );

  tlBall.to('.news_section_flying_ball', {
    duration: 3,
    ease: 'none',
    y: () => boxHeight('.news_section') - 450,
  });
};
