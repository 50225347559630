import gsap from 'gsap';
import { boxHeight } from 'utils/boxHeight';

export const portfolioPageAnimation = (height: number) => {
  gsap.to('.flying_words', {
    rotate: '360deg',
    repeat: -1,
    duration: 6,
    ease: 'linear',
    delay: 1,
  });
  const tlBall = gsap.timeline({
    scrollTrigger: {
      trigger: '.portfolio_page_content',
      start: `-150 top`,
      end: `${height} bottom`,
      scrub: 1,
      invalidateOnRefresh: true,
    },
  });
  tlBall.to('.portfolio_page_flying_ball', {
    duration: 1,
    ease: 'none',
    y: () => boxHeight('.portfolio_page_content') - 200,
  });
};
