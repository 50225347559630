import { Terms } from 'components/common/TermsTemplate';

export const termsConditions: Terms = {
  title: 'Terms and Conditions',
  createdAt: 'Last Updated: 1 May 2024',
  description:
    'Welcome to We Can Develop IT LLC ("We," "Us," "Our"). By accessing or using our website or services ("Services"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using our Services. If you do not agree with these Terms, please refrain from using our Services.',
  content: [
    { title: '1. Acceptance of Terms' },
    {
      description: [
        'By accessing our website and using our Services, you acknowledge that you have read, understood, and agreed to be bound by these Terms, as well as any applicable laws and regulations. These Terms apply to all visitors, users, and others who access or use our Services.',
      ],
    },
    { title: '2. Changes to Terms' },
    {
      description: [
        'We reserve the right to update or modify these Terms at any time. Any changes will be posted on this page, and the date of the latest update will be reflected at the top of the page. It is your responsibility to review these Terms periodically. Your continued use of our Services after any changes signifies your acceptance of the updated Terms.',
      ],
    },
    { title: '3. Services Provided' },
    {
      description: [
        'We Can Develop IT LLC offers custom software development services through our website and related platforms. These Services include, but are not limited to:',
      ],
    },
    {
      descriptionItems: [
        'Custom software development',
        'Web application development',
        'Mobile application development',
        'Software consultation and support',
      ],
    },
    {
      description: [
        'The availability and features of our Services may change from time to time at our discretion.',
      ],
    },
    { title: '4. User Responsibilities' },
    {
      description: ['As a user of our Services, you agree to:'],
    },
    {
      descriptionItems: [
        'Use our Services in compliance with all applicable laws and regulations.',
      ],
    },
    {
      descriptionItems: ['Provide accurate, up-to-date information when requested.'],
    },
    {
      descriptionItems: [
        'Refrain from any unauthorized use of our Services, such as accessing restricted areas, introducing viruses, or attempting to disrupt the Services.',
      ],
    },
    {
      descriptionItems: [
        'Not engage in any behavior that could harm or impair the operation of our Services or the experience of other users.',
      ],
    },
    { title: '5. Intellectual Property' },
    {
      description: [
        'All content, including text, graphics, logos, icons, images, software, and other materials found on the website, is the property of We Can Develop IT LLC or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, copy, reproduce, distribute, or modify any part of our content without our express written consent.',
      ],
    },
    { title: '6. Third-Party Links' },
    {
      description: [
        'Our website may contain links to third-party websites or services that are not owned or controlled by We Can Develop IT LLC. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we are not responsible for any damages or losses caused by your use of third-party websites or services.',
      ],
    },
    { title: '7. Limitation of Liability' },
    {
      description: [
        'To the fullest extent permitted by law, We Can Develop IT LLC shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of data, profits, or use, arising out of or related to your use of our Services, even if we have been advised of the possibility of such damages. We make no warranties or representations, whether express or implied, regarding the availability, accuracy, or reliability of our Services. All Services are provided on an "as-is" and "as-available" basis.',
      ],
    },
    { title: '8. Indemnification' },
    {
      description: [
        'You agree to indemnify, defend, and hold harmless We Can Develop IT LLC, its directors, employees, contractors, and agents, from and against any and all claims, damages, liabilities, costs, or expenses (including attorney’s fees) arising out of or related to your use of the Services, your violation of these Terms, or your violation of any third-party rights.',
      ],
    },
    { title: '9. Governing Law' },
    {
      description: [
        'These Terms shall be governed by and construed in accordance with the laws of the State of Tennessee, United States, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located in Franklin, Tennessee, to resolve any disputes or claims arising from these Terms or your use of our Services.',
      ],
    },
    { title: '10. Termination' },
    {
      description: [
        'These Terms shall be governed by and construed in accordance with the laws of the State of Tennessee, United States, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located in Franklin, Tennessee, to resolve any disputes or claims arising from these Terms or your use of our Services.',
      ],
    },
    { title: '11. Entire Agreement' },
    {
      description: [
        'These Terms, along with any additional legal notices or agreements we may publish on our website, constitute the entire agreement between you and We Can Develop IT LLC regarding the use of our Services and supersede any prior agreements or understandings, whether written or oral.',
      ],
    },
    { title: '12. Contact Us' },
    {
      description: [
        'If you have any questions about this Privacy Policy or how we handle your personal data, please contact us at:',
      ],
    },
    {
      descriptionItems: [
        'We Can Develop IT LLC',
        '12000 Ancient Crest Circle, Apt 12107',
        'Franklin, Tennessee, 37067',
        { item: 'Email:', linkTitle: 'business@wecandevelopit.com', link: 'business@wecandevelopit.com' },
      ],
    },
  ],
};
