import gsap from 'gsap';

export const commonAnimation = () => {

  gsap.to('.flying_words', {
    rotate:'360deg',
    repeat:-1,
    duration: 6,
    ease:'linear',
    delay:1
  });
};
