import useJsonRepeatPlay from 'hooks/useJsonRepeatPlay';
import Lottie from 'lottie-react';
import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { HowDoWeDo } from 'pages/main/how-do-we-do/consts';

interface IProps {
  howDoWeDo: HowDoWeDo;
}

const loadJson = (path: string) => {
  return import(`assets/main/how-do-we-do/${path}`);
};
const HowDoWeDoCard: FC<IProps> = ({ howDoWeDo }) => {
  const { id, json, aboveTitle, title, descriptionTop, descriptionBottom, phrase } = howDoWeDo;
  const { animationData, isPlay, setIsPlay, setHasPlayed, handleMouseEnter } = useJsonRepeatPlay({
    json,
    loadJson,
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  useEffect(() => {
    if (inView) {
      handleMouseEnter();
    }
  }, [inView]);

  const boxRef = document.querySelector('.howDoWeDo_section_json');
  useEffect(() => {
    if (!boxRef) return;
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const element = mutation.target as HTMLElement;
          const opacity = getComputedStyle(element).opacity;

          if (opacity === '1') {
            handleMouseEnter();
          }
        }
      });
    });
    observer.observe(boxRef, { attributes: true });
    return () => {
      observer.disconnect();
    };
  }, [boxRef]);

  return (
    <Box
      sx={{
        width: { xs: '100%', md: '100vw' },
        minWidth: { xs: '100%', md: '1650px' },
        height: { xs: 'auto', md: '954px' },
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        mb: { xs: '50px', md: '0px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          minHeight: { xs: '0px', md: '943px' },
          gap: '30px',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Typography
          className="howDoWeDo_section_phrase"
          sx={{
            display: { xs: 'none', md: 'block' },
            fontSize: { xs: '20px', md: '40px' },
            fontWeight: 700,
            lineHeight: { xs: '25px', md: '50px' },
            fontFamily: 'Caveat',
            position: 'absolute',
            bottom: '150px',
            right: '140px',
            color: theme.palette.text.primary,
            zIndex: 2,
          }}
        >
          {phrase}
        </Typography>

        <Box
          sx={{
            width: { xs: '100%', md: '626px' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '24px',
            color: theme.palette.text.primary,
            position: 'relative',
            zIndex: 2,
            mt: { xs: id === 1 ? '100px' : '0px', md: '140px' },
            left: '0px',
          }}
        >
          {id == 1 && (
            <Typography
              sx={{
                fontSize: { xs: '36px', md: '64px' },
                fontWeight: 800,
                lineHeight: { xs: '54px', md: '96px' },
                textAlign: 'left',
                mb: { xs: '32px', md: '54px' },
                ml: { xs: '16px', md: '0px' },
                color: theme.palette.text.primary,
                zIndex: 2,
                width: '100%',
              }}
            >
              How Do We Do IT?
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: id !== 1 ? { xs: '0px', md: '174px' } : '0px',
              px: { xs: '16px', md: '0px' },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '24px' },
                fontWeight: 700,
                lineHeight: { xs: '30px', md: '36px' },
                textAlign: 'left',
              }}
            >
              {aboveTitle}
            </Typography>
            <Typography
              className="howDoWeDo_section_title"
              sx={{
                fontSize: { xs: '56px', md: '150px' },
                fontWeight: 800,
                lineHeight: { xs: '64px', md: '174px' },
                textAlign: 'left',
                color: {
                  xs: theme.palette.orange.main,
                  md: id === 1 ? theme.palette.text.primary : theme.palette.orange.main,
                },
                transition: '0.5s',
                mt: { xs: '16px', md: '0px' },
              }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '24px' },
              fontWeight: 400,
              lineHeight: { xs: '21px', md: '36px' },
              textAlign: 'left',
              width: { xs: '100%', md: '630px' },
              boxSizing: 'border-box',
              px: { xs: '16px', md: '0px' },
            }}
          >
            {descriptionTop}
            <br />
            <br />
            {descriptionBottom}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            position: 'relative',
            width: { xs: '100%', md: '945px' },
          }}
        >
          <Box
            className="howDoWeDo_section_json"
            ref={ref}
            sx={{
              width: { xs: '100%', md: '975px' },
              position: { xs: 'relative', md: 'absolute' },
              bottom: '-5px',
              left: '0px',
              cursor: 'pointer',
              opacity: {
                xs: 1,
                md: id === 1 ? 0 : 1,
              },
            }}
          >
            <Typography
              className="howDoWeDo_section_phrase"
              sx={{
                display: { xs: 'block', md: 'none' },
                fontSize: { xs: '20px', md: '40px' },
                fontWeight: 700,
                lineHeight: { xs: '25px', md: '50px' },
                fontFamily: 'Caveat',
                position: 'absolute',
                bottom: '45px',
                right: '16px',
                color: theme.palette.orange.main,
                zIndex: 2,
              }}
            >
              {phrase}
            </Typography>
            <Box
              sx={{
                height: { xs: '430px', md: '973px' },
                maxWidth: { xs: '500px', md: '100%' },
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Lottie
                onMouseEnter={() => {
                  json && handleMouseEnter();
                }}
                onMouseLeave={() => {
                  json && setIsPlay(false);
                  json && setHasPlayed(false);
                }}
                animationData={animationData}
                autoplay={true}
                loop={isPlay}
                onComplete={() => {
                  setHasPlayed(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowDoWeDoCard;
