import useJsonRepeatPlay from 'hooks/useJsonRepeatPlay';
import Lottie from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { Services } from 'pages/main/services/consts';

import ServicePopup from '../ServicesPopup';

interface IProps {
  services: Services;
}

const loadJson = (path: string) => {
  return import(`assets/main/services/${path}`);
};
const ServicesCard: FC<IProps> = ({ services }) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const {
    title,
    description,
    image,
    json,
    imageBoxSX,
    textBoxSX,
    mainBoxSX,
    jsonStyle,
    textSX,
    icon: Icon,
    textTitleSX,
    popupInfo,
    imageMV,
  } = services;
  const { animationData, isPlay, setIsPlay, setHasPlayed, handleMouseEnter } = useJsonRepeatPlay({
    json,
    loadJson,
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  useEffect(() => {
    if (inView) {
      handleMouseEnter();
    }
  }, [inView]);
  return (
    <Box
      onClick={() => {
        setIsModal(true);
      }}
      onMouseEnter={() => {
        json && handleMouseEnter();
      }}
      onMouseLeave={() => {
        json && setIsPlay(false);
        json && setHasPlayed(false);
      }}
      sx={{
        alignSelf: 'center',
        justifySelf: 'center',
        display: 'flex',
        minWidth: { xs: '343px', md: '410px' },
        maxWidth: { xs: '343px', md: '410px' },
        width: '100%',
        height: { xs: '343px', md: '374px' },
        overflow: 'hidden',
        position: 'relative',
        p: { xs: '16px', md: '24px' },
        boxSizing: 'border-box',
        borderRadius: '20px',
        cursor: 'pointer',
        '&:hover': {
          transform: `scale(1.05)`,
          zIndex: 11,
        },
        ...mainBoxSX,
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <Icon style={{ position: 'absolute', right: '24px', bottom: '24px', zIndex: 10 }} />
      <Box
        sx={{
          color: theme.palette.text.secondary,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          ...textBoxSX,
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
            textAlign: 'left',
            position: 'relative',
            zIndex: 10,
            ...textTitleSX,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            mb: { xs: '0px', md: '24px' },
            position: 'relative',
            zIndex: 10,
            ...textSX,
          }}
        >
          {description}
        </Typography>
      </Box>
      {image && (
        <Box
          component="img"
          src={image}
          sx={{ display: { xs: 'none', md: 'block' }, ...imageBoxSX }}
        />
      )}
      {image && (
        <Box
          component="img"
          src={imageMV ? imageMV : image}
          sx={{ display: { xs: 'block', md: 'none' }, ...imageBoxSX }}
        />
      )}
      {json && (
        <Box ref={ref} sx={{ ...jsonStyle }}>
          <Lottie
            style={{ zIndex: 1 }}
            animationData={animationData}
            autoplay={true}
            loop={isPlay}
            onComplete={() => {
              setHasPlayed(false);
            }}
          />
        </Box>
      )}
      {isModal && (
        <ServicePopup
          title={title}
          popupInfo={popupInfo}
          isModal={isModal}
          setIsModal={setIsModal}
          popupInfoSecond={services?.popupInfoSecond}
        />
      )}
    </Box>
  );
};

export default ServicesCard;
