import React from 'react';
import { theme } from 'theme';

import { Box } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import HowDoWeDoCard from 'components/main/how-do-we-do/HowDoWeDoCard';

import { ReactComponent as HowDoWeDoIT } from 'assets/main/how-do-we-do/HowDoWeDoIT.svg';
import { ReactComponent as HowDoWeDoITMV } from 'assets/main/how-do-we-do/HowDoWeDoITMV.svg';

import { useMediaSize } from '../../../hooks/useDeviceDetector';
import { howDoWeDo } from './consts';

const HowWeDoWeDoIt = () => {
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  const { isDesktop } = useMediaSize();
  return (
    <Box
      id="howdowedo"
      className={'howDoWeDo_section'}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        height: { xs: '100%', md: '100vh' },
        display: 'flex',
        alignItems: 'center',
        backgroundColor: { xs: theme.palette.black.main, md: theme.palette.text.primary },
      }}
    >
      <Box
        className={'howDoWeDo_section_hide'}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'translate(-50%,-50%)',
          width: '0px',
          height: '0px',
          backgroundColor: theme.palette.black.main,
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: { xs: '0px', md: '50%' },
          transform: { xs: 'none', md: 'translateX(-50%)' },
          zIndex: 2,
        }}
      >
        {isDesktop ? (
          <HowDoWeDoIT
            style={{
              position: 'absolute',
              top: '70px',
              right: '0px',
              maxWidth: '1671px',
              width: '100%',
              height: 'auto',
            }}
          />
        ) : (
          <HowDoWeDoITMV
            style={{
              position: 'absolute',
              top: '40px',
              left: '11px',
              maxWidth: '1024px',
              width: '95%',
              height: 'auto',
            }}
          />
        )}
      </Box>

      <Box
        className={'howDoWeDo_section_full'}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: { xs: '100%', md: '300vw' },
        }}
      >
        {howDoWeDo.map((item) => (
          <HowDoWeDoCard key={item.id} howDoWeDo={item} />
        ))}
      </Box>
      <FlyingBall
        clsName="howDoWeDo_section_flying_ball"
        setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
        bgClr={theme.palette.orange.main}
        sx={{ right: '0px', bottom: '50px', position: 'fixed' }}
      />
    </Box>
  );
};

export default HowWeDoWeDoIt;
