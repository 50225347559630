import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';

import { ReactComponent as WUpper } from 'assets/main/who-we-are/WW.svg';
import { ReactComponent as WWA } from 'assets/main/who-we-are/WhoWeAre.svg';
import WhoWeAreFifth from 'assets/main/who-we-are/WhoWeAreFifth.webp';
import WhoWeAreFirst from 'assets/main/who-we-are/WhoWeAreFirst.webp';
import WhoWeAreFourth from 'assets/main/who-we-are/WhoWeAreFourth.webp';
import WhoWeAreSecond from 'assets/main/who-we-are/WhoWeAreSecond.webp';
import WhoWeAreThird from 'assets/main/who-we-are/WhoWeAreThird.webp';
import { ReactComponent as A } from 'assets/main/who-we-are/a.svg';
import { ReactComponent as E } from 'assets/main/who-we-are/e.svg';
import { ReactComponent as H } from 'assets/main/who-we-are/h.svg';
import { ReactComponent as O } from 'assets/main/who-we-are/o.svg';
import { ReactComponent as R } from 'assets/main/who-we-are/r.svg';
import { ReactComponent as W } from 'assets/main/who-we-are/w.svg';

const WhoWeAre = () => {
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  const { isDesktop } = useMediaSize();
  return (
    <Box
      id="whoweare"
      className="wwa_section"
      sx={{
        overflow: 'hidden',
        height: '100vh',
        backgroundColor: { xs: 'transparent', md: theme.palette.black.main },
        position: 'relative',
      }}
    >
      <FlyingBall
        clsName="wwa_section_flying_ball"
        setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
        sx={{ right: '0px', bottom: '50px', position: 'fixed' }}
      />
      <Box
        className="wwa_section_hide"
        sx={
          isDesktop
            ? {
                borderRadius: '50%',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.text.primary,
                transition: '0.2s',
              }
            : {}
        }
      >
        <Box
          className="wwa_section_content"
          sx={{
            opacity: { xs: 1, md: 0 },
            overflow: 'hidden',
            maxWidth: '100%',
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
            position: 'relative',
            backgroundColor: theme.palette.text.primary,
            transition: '0.3s',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              maxWidth: { xs: '224px', md: '400px' },
              width: '100%',
              px: '10px',
              boxSizing: 'border-box',
            }}
          >
            <WWA
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </Box>

          <Box
            sx={{
              boxSizing: 'border-box',
              width: '3840px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              gap: { xs: '0px', md: '178px' },
              height: '100%',
              ml: { xs: '0px', md: '35vw' },
            }}
          >
            <Box
              className="wwa_section_text"
              sx={{
                display: 'flex',
                alignItems: 'end',
                margin: 'auto',
                width: '3840px',
                height: '411px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                zIndex: 1,
                ml: '170px',
              }}
            >
              <WUpper style={{ marginRight: '51px' }} />
              <H style={{ marginRight: '62px' }} />
              <O style={{ marginRight: '164px', zIndex: 3 }} />
              <W style={{ marginRight: '26px' }} />
              <E style={{ marginRight: '178px' }} />
              <A style={{ marginRight: '82px' }} />
              <R style={{ marginRight: '35px' }} />
              <E />
            </Box>

            <Box
              className="wwa_section_photo"
              sx={{
                display: 'flex',
                alignItems: 'center',
                zIndex: 2,
                width: '3840px',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: '373px', md: '550px' },
                  height: 'auto',
                  ml: '100px',
                }}
                src={WhoWeAreThird}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  flexDirection: 'row',
                  gap: '14px',
                }}
              >
              <Box
                component="img"
                src={WhoWeAreSecond}
                sx={{
                  width: { xs: '373px', md: '550px' },
                  height: 'auto',
                  ml: '1090px',
                }}
              />
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '14px' },
                    fontWeight: 400,
                    lineHeight: { xs: '24px', md: '16px' },
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                    width: '387px',
                  }}
                >
                  <span style={{ fontWeight: 800 }}>Kenn Palm</span>
                  <br />
                  {`founder, brings 20+ years in tech leadership, excelling in remote team cohesion and complex project delivery`}
                </Typography>
              </Box>

              <Box
                component="img"
                src={WhoWeAreFirst}
                sx={{
                  width: { xs: '373px', md: '550px' },
                  height: 'auto',
                  ml: '1007px',
                }}
              />
            </Box>

            <Box
              className="wwa_section_photo"
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '3840px',
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'row',
                  gap: '14px',
                }}
              >
                <Box
                  component="img"
                  src={WhoWeAreFourth}
                  sx={{
                    width: { xs: '373px', md: '550px' },
                    height: 'auto',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '14px' },
                    fontWeight: 400,
                    lineHeight: { xs: '24px', md: '16px' },
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                    width: '387px',
                  }}
                >
                  <span style={{ fontWeight: 800 }}>Vitaly Opryshko</span>
                  <br />
                  {`founder, a high-tech pioneer with 30+ years' experience, excels in leading complex projects and fostering client success`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'row',
                  gap: '14px',
                }}
              >
              <Box
                component="img"
                src={WhoWeAreFifth}
                sx={{
                  width: { xs: '373px', md: '550px' },
                  height: 'auto',
                }}
              />
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '14px' },
                    fontWeight: 400,
                    lineHeight: { xs: '24px', md: '16px' },
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                    width: '387px',
                  }}
                >
                  <span style={{ fontWeight: 800 }}>Yaroslav Volovoj</span>
                  <br />
                  {`business developer, specializes in blockchain, Web 3.0, MR, and scaling innovative startups`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WhoWeAre;
