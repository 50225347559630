export interface HowDoWeDo {
  id: number;
  json: string;
  aboveTitle: string;
  title: string;
  descriptionTop: string;
  descriptionBottom: string;
  phrase: string;
}

export const howDoWeDo: HowDoWeDo[] = [
  {
    id: 1,
    json: 'Why.json',
    aboveTitle: 'It All Starts With One Question:',
    title: '“Why?”',
    descriptionTop:
      'Before reaching for tools, a true expert first asks “Why?” Understanding the purpose behind solving a problem shapes every stage of the process — discovery, design, development, and deployment',
    descriptionBottom:
      'Once we understand “Why,” we can clearly define “What” needs to be built to achieve the desired outcome',
    phrase: '“No stone left unturned”',
  },
  {
    id: 2,
    json: 'What.json',
    aboveTitle: 'The Evasive',
    title: '“What?”',
    descriptionTop:
      'With 25+ years of IT experience, we help clients define the “What” — the foundation of every project. Through trust and active listening, we understand the challenges, determine what to build, and assess potential risks',
    descriptionBottom:
      'Once this is clear, we focus on the next key question — "How" to bring the vision to life',
    phrase: '"The devil is in the details"',
  },
  {
    id: 3,
    json: 'How.json',
    aboveTitle: 'The Right Tools for Every',
    title: '“How?”',
    descriptionTop:
      'A true craftsman knows every challenge needs the right tool. Our IT specialists draw on years of expertise to select the best tools for each project',
    descriptionBottom:
      'We reject one-size-fits-all solutions, ensuring our approach is tailored to your needs, delivering a seamless, efficient solution that works from day one',
    phrase: '"It\'s not what you do, it\'s how you do it"',
  },
];
