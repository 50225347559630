import React from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import PortfolioCard from 'components/main/portfolio/PortfolioCard';

import { portfolioBottomRow, portfolioTopRow } from 'pages/main/portfolio/consts';

import { ReactComponent as Pen } from 'assets/main/porfolio/Pen.svg';
import { ReactComponent as Portfolio } from 'assets/main/porfolio/Portfolio.svg';
import { ReactComponent as Ruler } from 'assets/main/porfolio/Ruler.svg';
import { useSectionContext } from 'components/common/AppBar/SectionContext';

const PortfolioSection = () => {
  const{setActiveSection}=useSectionContext()
  const navigate = useNavigate();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  return (
    <Box
      id="portfolio"
      className="portfolio_section"
      sx={{
        overflow: 'hidden',
        width: '100%',
        backgroundColor: theme.palette.black.main,
        p: '0 24px',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '1920px',
          width: '100%',
          minHeight: { xs: '1242px', md: '1460px' },
          p: { xs: '100px 0 100px 0', md: '140px 0 140px 0' },
          margin: 'auto',
          boxSizing: 'border-box',
          backgroundColor: theme.palette.black.main,
        }}
      >
        <FlyingBall
          clsName="portfolio_section_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          sx={{ right: 0, top: 0 }}
          bgClr={theme.palette.orange.main}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '47px', md: '69px' },
            left: { xs: '24px', md: '52%' },
            transform: { xs: 'none', md: 'translateX(-50%)' },
          }}
        >
          <Portfolio
            style={{
              maxWidth: '1070px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '450px',
            left: '230px',
            transform: 'translateX(-50%)',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Ruler />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '218px',
            right: '-190px',
            transform: 'translateX(-50%)',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Pen />
        </Box>
        <Box
          sx={{
            minHeight: { xs: '1042px', md: '1180px' },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              color: theme.palette.text.primary,
              maxWidth: '1290px',
              margin: '0 auto',
              mb: { xs: '32px', md: '54px' },
            }}
          >
            Portfolio
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '24px', md: '30px' } }}>
            <Box
              className="portfolio_section_top_row"
              sx={{ display: 'flex', gap: { xs: '16px', md: '30px' } }}
            >
              {portfolioTopRow.map((card) => (
                <PortfolioCard key={card.id} portfolio={card} />
              ))}
            </Box>
            <Box
              className="portfolio_section_bottom_row"
              sx={{ display: 'flex', gap: { xs: '16px', md: '30px' }, justifyContent: 'end' }}
            >
              {portfolioBottomRow.map((card) => (
                <PortfolioCard key={card.id} portfolio={card} />
              ))}
            </Box>
          </Box>
          <CustomButton
            sx={{
              margin: 'auto',
              mt: '32px',
              width:'100%',
              maxWidth: {xs: '500px',md:'182px'},
              height: '56px',
              borderRadius: '30px',
              backgroundColor: theme.palette.orange.main,
              color: theme.palette.text.secondary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
            onClick={() => {
              navigate('/portfolio');
              setActiveSection('portfolio')
            }}
          >
            View all projects
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioSection;
