import gsap from 'gsap';
import { boxHeight } from 'utils/boxHeight';

export const servicesAnimation = (setActiveSection: (id: string | null) => void) => {
  gsap.set(
    [
      '.services_card_1',
      '.services_card_2',
      '.services_card_3',
      '.services_card_4',
      '.services_card_5',
      '.services_card_6',
      '.services_card_7',
      '.services_card_8',
      '.services_card_9',
    ],
    {
      opacity: 0,
      scale: 0,
    }
  );
  gsap.fromTo(
    '.services_card_1',
    {
      xPercent: -75,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_1',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_2',
    {
      xPercent: 0,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_2',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_3',
    {
      xPercent: 75,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_3',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_4',
    {
      xPercent: -50,
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_4',
        start: '-100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_5',
    {
      xPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_5',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_6',
    {
      xPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_6',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_7',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_7',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_8',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_8',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.services_card_9',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.services_card_9',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );

  const tlBall = gsap.timeline({
    scrollTrigger: {
      trigger: '.services_section',
      start: 'top center',
      end: 'bottom center',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('services');
      },
      onEnterBack: () => {
        setActiveSection('services');
      },
      onLeaveBack: () => {
        setActiveSection(null);
      },
    },
  });
  tlBall.fromTo(
    '.services_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      duration: 1,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    }
  );

  tlBall.to('.services_section_flying_ball', {
    duration: 3,
    ease: 'none',
    y: () => boxHeight('.services_section_inner') - 100,
  });

  tlBall.to('.services_section_flying_ball', {
    duration: 1,
    rotate: '+=360deg',
    opacity: 0,
    scale: 0,
  });
};

export const servicesAnimationMV = () => {
  gsap.set(['.services_card'], {
    opacity: 0,
    scale: 0,
  });
  document.querySelectorAll('.services_card').forEach((el) => {
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      opacity: 1,
      scale: 1,
    });
  });
};
