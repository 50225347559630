import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import Construct from 'assets/main/process/Construct.webp';
import Deploy from 'assets/main/process/Deploy.webp';
import Design from 'assets/main/process/Design.webp';
import Discover from 'assets/main/process/Discover.webp';
import Invent from 'assets/main/process/Invent.webp';
import BlockchainDevelopment from 'assets/main/services/BlockchainDevelopment.webp';
import BotsAndAI from 'assets/main/services/BotsAndAI.webp';
import CRM from 'assets/main/services/CRM.webp';
import ECommerce from 'assets/main/services/ECommerce.webp';
import ITSupportAndMaintenance from 'assets/main/services/ITSupportAndMaintenance.webp';
import MobileWebApplications from 'assets/main/services/MobileWebApplications.webp';
import BlackArrow from 'assets/common/FlyingButton/BlackArrow.webp';
import BlackCircle from 'assets/common/FlyingButton/BlackCircle.webp';
import OrangeCircle from 'assets/common/FlyingButton/OrangeCircle.webp';
import WhiteArrow from 'assets/common/FlyingButton/WhiteArrow.webp';
import WhiteCircle from 'assets/common/FlyingButton/WhiteCircle.webp';
import CustomAppBar from '../common/AppBar';
import { Footer } from '../common/Footer';
import { GotAProjectDrawer } from '../common/GotAProject';
import { useGotAProject } from '../common/GotAProject/GotAProjectContext';
import { initializeGA, logPageView } from 'utils/googleAnalytics';

const MAIN_IMAGES_DESKTOP: string[] = [
  BlockchainDevelopment,
  BotsAndAI,
  CRM,
  ECommerce,
  ITSupportAndMaintenance,
  MobileWebApplications,
  Construct,
  Deploy,
  Design,
  Discover,
  Invent,
  BlackArrow,
  BlackCircle,
  OrangeCircle,
  WhiteArrow,
  WhiteCircle,
];
const SECONDARY_IMAGES_DESKTOP: string[] = [];

const MainLayout = () => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isOpenGotAProjectDrawer, setIsOpenGotAProjectDrawer } = useGotAProject();
  const preloadImages = (imageUrls: string[]): Promise<void[]> => {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = () => {
          console.error(`Failed to load image: ${url}`);
          resolve();
        };
      });
    });
    return Promise.all(promises);
  };
  useEffect(() => {
    (async () => {
      await preloadImages(MAIN_IMAGES_DESKTOP);
      setShouldRender(true);
      initializeGA()
      logPageView(window.location.pathname);
      await preloadImages(SECONDARY_IMAGES_DESKTOP);
    })();
  }, []);
  if (!shouldRender) {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CustomAppBar />
      <GotAProjectDrawer
        isOpenGotAProjectDrawer={isOpenGotAProjectDrawer}
        setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
      />
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;
