import { throttle } from 'lodash';
import { debounce } from 'lodash';
import { FC, useCallback, useRef, useState, Dispatch, SetStateAction, MouseEvent } from 'react';
import { theme } from 'theme';

import { Box, BoxProps } from '@mui/material';

import { ReactComponent as BlackArrow } from 'assets/common/FlyingButton/BlackArrow.svg';
import BlackCircle from 'assets/common/FlyingButton/BlackCircle.webp';
import OrangeCircle from 'assets/common/FlyingButton/OrangeCircle.webp';
import { ReactComponent as WhiteArrow } from 'assets/common/FlyingButton/WhiteArrow.svg';
import WhiteCircle from 'assets/common/FlyingButton/WhiteCircle.webp';

interface IProps extends BoxProps {
  setIsOpenGotAProjectDrawer: Dispatch<SetStateAction<boolean>>;
  clsName?: string;
  bgClr?: string;
}

const FlyingBall: FC<IProps> = ({
  setIsOpenGotAProjectDrawer,
  sx,
  clsName,
  bgClr = theme.palette.black.main,
}) => {
  const parentWidth = 300;
  const ballWidth = 190;
  const centerPosition = (parentWidth - ballWidth) / 2;
  const [position, setPosition] = useState({ x: centerPosition, y: centerPosition });
  const ref = useRef<HTMLDivElement | null>(null);
  const refParent = useRef<HTMLDivElement | null>(null);
  const [isHover, setIsHover] = useState<boolean>(false);
  const throttledMouseMove = useCallback(
    throttle((event: MouseEvent) => {
      if (ref?.current && refParent.current) {
        const parentRect = refParent.current?.getBoundingClientRect();
        const mouseX = event.clientX - parentRect.left - ref.current?.offsetWidth / 2;
        const mouseY = event.clientY - parentRect.top - ref.current?.offsetHeight / 2;
        const x = Math.max(0, Math.min(mouseX, parentRect.width - ref.current?.offsetWidth));
        const y = Math.max(0, Math.min(mouseY, parentRect.height - ref.current?.offsetWidth));
        setPosition({ x, y });
      }
    }, 100),
    []
  );

  const handleMouseMove = (event: MouseEvent) => {
    throttledMouseMove(event);
  };
  const handleMouseLeave = debounce(() => {
    const x = centerPosition;
    const y = centerPosition;
    setPosition({ x, y });
  }, 300);
  return (
    <Box
      className={clsName}
      ref={refParent}
      sx={{
        width: `${parentWidth}px`,
        height: `${parentWidth}px`,
        display: {xs:'none', md: 'flex' },
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '50%',
        position: 'absolute',
        zIndex: 100,
        ...sx,
      }}
      onMouseMove={(e) => {
        const parentRect = refParent.current?.getBoundingClientRect();
        const width = parentRect?.width;
        if (width === parentWidth) {
          handleMouseMove(e);
        }
      }}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        className="flying_dynamic_block"
        onClick={() => {
          setIsOpenGotAProjectDrawer(true);
        }}
        ref={ref}
        sx={{
          width: `${ballWidth}px`,
          height: `${ballWidth}px`,
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`,
          transition: '0.2s ease-out',
          cursor: 'pointer',
          transform: position.x === centerPosition ? 'scale(1)' : 'scale(0.8)',
        }}
      >
        <BlackArrow
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 20,
            display: isHover
              ? bgClr === '#0F0F0F'
                ? 'block'
                : 'block'
              : bgClr === '#0F0F0F'
                ? 'none'
                : 'block',
          }}
        />

        <WhiteArrow
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 20,
            display: isHover
              ? bgClr === '#0F0F0F'
                ? 'none'
                : 'none'
              : bgClr === '#0F0F0F'
                ? 'block'
                : 'none',
          }}
        />
        <Box
          className="flying_words"
          component="img"
          src={
            isHover
              ? bgClr === '#0F0F0F'
                ? OrangeCircle
                : WhiteCircle
              : bgClr === '#0F0F0F'
                ? BlackCircle
                : OrangeCircle
          }
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        />
      </Box>
    </Box>
  );
};

export default FlyingBall;
