import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    orange: {
      main: string;
    };
    black: {
      main: string;
    };

  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl:true;
  }

  interface PaletteOptions {
    orange?: {
      main: string;
    };
    black?: {
      main: string;
    };
  }
}

const primary = {
  main: '#178DFA',
  text: '#000000',
};

export const theme = createTheme({
  palette: {
    background: {
      default: '#101638',
    },
    primary,
    secondary: {
      main: '#E0F1F0',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#000000',
      disabled: '#A6A8AA',
    },
    warning: {
      main: '#ce1637',
      light: '#ee6881',
      dark: '#a42e44',
    },
    error: {
      main: '#FC395C',
      light: '#FFEBEF',
      dark: '#FFD9E0',
    },
    grey: {
      300: '#F5F5F5',
      600: '#B1B1B1',
    },
    black:{
      main: '#0F0F0F'
    },
    orange: {
      main: '#FF7A01',
    },
    common: {
      black: primary.text,
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Manrope', 'sans-serif'].join(','),
    fontWeightRegular: 400,
    fontSize: 14,
    h2: {
      fontSize: 30,
      fontWeight: 700,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: 1.44,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1025,
      lg: 1280,
      xl: 1355,
      xxl:1500,
      xxxl:1920
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            backgroundColor: '#A6A8AA',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #0F0F0F',
            WebkitTextFillColor: '#0F0F0F',
            borderRadius: '0px'
          },
        },
      }},
  },
});
