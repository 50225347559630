import React from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { ReactComponent as Dream } from 'assets/main/dream/Dream.svg';
import { ReactComponent as LeftQuotes } from 'assets/main/dream/LeftQuotes.svg';
import { ReactComponent as RightQuotes } from 'assets/main/dream/RightQuotes.svg';

const DreamSection = () => {
  return (
    <Box
      id="dream"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: { xs: '275px', md: '568px' },
          p: { xs: '50px 16px', md: '140px 24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '14px',
            right: '63px',
          }}
        >
          <Dream
            style={{
              maxWidth: '928px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1105px' },
            width: '100%',
            margin: 'auto',
            display: 'flex',
          }}
        >
          <LeftQuotes style={{}} />
          <Typography
            className={'dream_section_text'}
            sx={{
              fontSize: { xs: '36px', md: '96px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '144px' },
              textAlign: 'center',
              color: theme.palette.text.secondary,
              maxWidth: '959px',
            }}
          >
            Got a tech dream? We Can Develop IT!
          </Typography>
          <RightQuotes
            style={{
              alignSelf: 'center',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DreamSection;
