import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

import { Box, Link as MuiLink, Typography } from '@mui/material';

import { ReactComponent as Pen } from 'assets/terms/Pen.svg';

export interface TermsContent {
  title?: string;
  description?: string[];
  descriptionItems?: (string | { item: string; linkTitle: string; [key: string]: string })[];
  descriptionItemsWithoutDot?: string[];
  descriptionItemslink?: {
    title: string;
    link: string;
  };
  link?: {
    title: string;
    link: string;
  };
  externalLinks?: {
    title: string;
    links: {
      title: string;
      link: string;
    }[];
  };
}

export interface Terms {
  title: string;
  createdAt: string;
  description: string;
  content: TermsContent[];
}

interface IProps {
  terms: Terms;
}

const TermsTemplate: FC<IProps> = ({ terms }) => {
  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '1920px',
        width: '100%',
        position: 'relative',
        p: { xs: '120px 16px 100px 16px', md: '220px 24px 140px 24px' },
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '1290px',
          width: '100%',
          margin: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '36px', md: '64px' },
            fontWeight: 800,
            lineHeight: { xs: '54px', md: '96px' },
            textAlign: 'center',
            mb: { xs: '32px', md: '54px' },
            color: theme.palette.text.secondary,
          }}
        >
          {terms.title}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
            textAlign: 'left',
            mb: '8px',
            color: theme.palette.text.secondary,
          }}
        >
          {terms.createdAt}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            mb: '8px',
            color: theme.palette.text.secondary,
          }}
        >
          {terms.description}
        </Typography>
      </Box>
      <Box
        sx={{
          margin: 'auto',
          maxWidth: '1920px',
          width: '100%',
          position: 'relative',
        }}
      >
        <Pen
          style={{
            position: 'absolute',
            top: '-48px',
            right: '50px',
            maxWidth: '800px',
            width: '100%',
            height: 'auto',
          }}
        />
        <Box
          sx={{
            margin: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '1290px',
            width: '100%',
          }}
        >
          {terms.content.map((item, index) => (
            <Box key={index}>
              {item?.title && (
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '36px',
                    textAlign: 'left',
                    my: '16px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {item.title}
                </Typography>
              )}
              {item?.description &&
                item?.description.map((item, index) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                      mb: '8px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              {item?.descriptionItems &&
                item?.descriptionItems.map((item, index) => {
                  return (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                      <span
                        style={{
                          color: theme.palette.orange.main,
                          fontSize: '40px',
                          lineHeight: '14px',
                          marginBottom: '10px',
                        }}
                      >
                        •
                      </span>
                      <Box sx={{ display: 'flex', flex: 'wrap', gap: '4px' }}>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '21px',
                            textAlign: 'left',
                            mb: '4px',
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {typeof item === 'string' ? item : item?.item}
                        </Typography>
                        {typeof item !== 'string' && (
                          <Link
                            to={`mailto:${item.link}`}
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '21px',
                              textAlign: 'left',
                              marginBottom: '4px',
                              color: theme.palette.text.secondary,
                              textDecorationColor: theme.palette.text.secondary,
                            }}
                          >
                            {item.linkTitle}
                          </Link>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              {item?.descriptionItemsWithoutDot &&
                item?.descriptionItemsWithoutDot.map((item, index) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                      mb: '4px',
                      ml: '41px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              {item?.descriptionItemslink && (
                <MuiLink
                  href={item.descriptionItemslink?.link}
                  target="_blank"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    textAlign: 'left',
                    mb: '4px',
                    ml: '41px',
                    color: theme.palette.text.secondary,
                    textDecorationColor: theme.palette.text.secondary,
                  }}
                >
                  {item.descriptionItemslink?.title}
                </MuiLink>
              )}
              {item?.externalLinks && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mt: '20px',gap:'4px' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {item?.externalLinks?.title}
                  </Typography>
                  {item?.externalLinks?.links.map((link, ind) => (
                    <MuiLink
                      key={ind}
                      href={link?.link}
                      target="_blank"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '21px',
                        textAlign: 'left',
                        mb:'3px',
                        color: theme.palette.text.secondary,
                        textDecorationColor: theme.palette.text.secondary,
                      }}
                    >{link?.title}{' '}</MuiLink>
                  ))}
                </Box>
              )}
              {item?.link && (
                <Link
                  to={`mailto:${item.link.link}`}
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    textAlign: 'left',
                    marginBottom: '4px',
                    color: theme.palette.text.secondary,
                    textDecorationColor: theme.palette.text.secondary,
                  }}
                >
                  {item.link.title}
                </Link>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TermsTemplate;
