import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Divider, Typography, Link as MuiLink } from '@mui/material';

import { ReactComponent as Up } from 'assets/common/Up.svg';
import { ReactComponent as Logo } from 'assets/common/logo.svg';

import { pages, policies, social } from './consts';
import { useMediaSize } from 'hooks/useDeviceDetector';
import FooterMV from './FooterMV';

export const Footer = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const{isDesktop} = useMediaSize()
  if(!isDesktop){
    return <FooterMV/>
  }
  return (
    <Box
      id="footer"
      sx={{
        background: pathname === '/portfolio' ? '#1B1B1B' : theme.palette.black.main,
        position: 'relative',
        p: '0px 130px',
      }}
    >
      <Up
        onClick={() => {
          window.scrollTo({ top:0, behavior: 'auto' });
        }}
        style={{
          position: 'absolute',
          right: '67px',
          bottom: '110px',
          cursor: 'pointer',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '1290px',
          minHeight: '437px',
          margin: 'auto',
          p: '70px 24px',
          boxSizing: 'border-box',
          position: 'relative',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            width: '100%',
            gap: '32px',
          }}
        >
          <Logo
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('');
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '40px',
              maxHeight: '160px',
              flexWrap: 'wrap',
            }}
          >
            {pages.map((item) => (
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  color: theme.palette.text.primary,
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '2px',
                    backgroundColor: theme.palette.text.primary,
                    bottom: '-1px',
                    left: 0,
                    transform: 'scaleX(0)',
                    transformOrigin: 'left',
                    transition: 'transform 0.3s ease-in-out',
                  },
                  '&:hover::after': {
                    transform: 'scaleX(1)',
                  },
                }}
                key={item.text}
                onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  if (pathname === '/') {
                    scrollHandler(e, item.link);
                    await new Promise((resolve) => setTimeout(resolve, 50));
                  } else {
                    navigate('/#' + item.link);
                  }
                }}
              >
                {item.text}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              display: 'flex',
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              gap: '30px',
            }}
          >
            {policies.map((item) => (
              <Typography
                onClick={()=>navigate(`${item.link}`)}
                key={item.link}
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '21px',
                  textDecoration: 'none',
                  color: theme.palette.text.primary,
                  position: 'relative',
                  cursor:'pointer',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '2px',
                    backgroundColor: theme.palette.text.primary,
                    bottom: '-1px',
                    left: 0,
                    transform: 'scaleX(0)',
                    transformOrigin: 'left',
                    transition: 'transform 0.3s ease-in-out',
                  },
                  '&:hover::after': {
                    transform: 'scaleX(1)',
                  },
                }}
              >
                {item.title}
              </Typography>
            ))}
          </Box>
          <Divider
            sx={{
              width: '100%',
              borderColor: theme.palette.text.primary,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                color: theme.palette.text.primary,
              }}
            >
              © 2024 WecandevelopIT. All rights reserved.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              {social.map((item) => (
                <MuiLink key={item.link} href={item.link} underline="none" target="_blank">
                  <Box component="img" alt="yotube" src={item.icon} />
                </MuiLink>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
