import Lottie from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { preloadImages } from 'utils/preloadingImages';

import { Box, Typography } from '@mui/material';

import { PopupInfo } from 'pages/main/services/consts';

import preloader from 'assets/main/services/popup/preloader.json';

interface IProps {
  title: string;
  popupInfo: PopupInfo;
}

const PopupContentBox: FC<IProps> = ({ popupInfo, title }) => {
  const [shouldRenderImage, setShouldRenderImage] = useState(false);
  useEffect(() => {
    (async () => {
      await preloadImages([popupInfo.popupImage]);
      setShouldRenderImage(true);
    })();
  });
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '655px',
        width: '100%',
        minHeight: '754px',
        p: { xs: '16px', md: '24px' },
        boxSizing: 'border-box',
        backgroundColor:
          popupInfo?.colorMode === 'light' ? theme.palette.text.primary : theme.palette.black.main,
        borderRadius: '20px',
        position: 'relative',
        ...popupInfo.popupBoxSX,
      }}
    >
      {!shouldRenderImage ? (
        <Lottie
          style={{
            position: 'absolute',
            left: '50%',
            top: popupInfo?.title === 'Web Applications' ? '25%' : '60%',
            transform: 'translate(-50%, -50%)',
          }}
          animationData={preloader}
          autoplay={true}
          loop={true}
        />
      ) : (
        <>
          <Box
            component="img"
            src={popupInfo?.popupImage}
            sx={{ display: { xs: 'none', md: 'block' }, ...popupInfo?.popupImageSX }}
          />
          <Box
            component="img"
            src={popupInfo?.popupImageMV ? popupInfo?.popupImageMV : popupInfo?.popupImage}
            sx={{ display: { xs: 'block', md: 'none' }, ...popupInfo?.popupImageSX }}
          />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          zIndex: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '24px', md: '32px' },
            fontWeight: 800,
            lineHeight: { xs: '36px', md: '48px' },
            textAlign: 'center',
            color:
              popupInfo?.colorMode === 'light'
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
          }}
        >
          {popupInfo.title ? popupInfo.title : title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ...popupInfo.popupTextSX,
          }}
        >
          {popupInfo?.popupDescription.map((item, ind) => (
            <Box
              key={ind}
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <span style={{ color: theme.palette.orange.main }}>•</span>
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: 400,
                  lineHeight: { xs: '21px', md: '24px' },
                  textAlign: 'left',
                  color:
                    popupInfo?.colorMode === 'light'
                      ? theme.palette.text.secondary
                      : theme.palette.text.primary,
                }}
              >
                {item}
                <br />
                <br />
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          zIndex: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'left',
            color:
              popupInfo?.colorMode === 'light'
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
          }}
        >
          Technologies:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          {popupInfo?.popupTechnologies.map((item) => (
            <Box
              key={item.popupTechnologiesText}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                maxWidth: { xs: '150px', md: '190px' },
                width: '100%',
                p: '8px',
                boxSizing: 'border-box',
                backgroundColor: '#F5F5F5',
                borderRadius: '50px',
              }}
            >
              <Box
                component="img"
                src={item.popupTechnologiesIcon}
              />
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '16px' },
                  fontWeight: 500,
                  lineHeight: { xs: '18px', md: '24px' },
                  textAlign: 'left',
                  color: theme.palette.text.secondary,
                }}
              >
                {item.popupTechnologiesText}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PopupContentBox;
