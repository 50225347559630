import CPlus from 'assets/main/news/pages/language-page/CPlus.webp'
import Go from 'assets/main/news/pages/language-page/Go.webp'
import Java from 'assets/main/news/pages/language-page/Java.webp'
import Kotlin from 'assets/main/news/pages/language-page/Kotlin.webp'
import PHP from 'assets/main/news/pages/language-page/PHP.webp'
import CSharp from 'assets/main/news/pages/language-page/CSharp.webp'
import JavaScript from 'assets/main/news/pages/language-page/JavaScript.webp'
import Python from 'assets/main/news/pages/language-page/Python.webp'
import Ruby from 'assets/main/news/pages/language-page/Ruby.webp'
import Rust from 'assets/main/news/pages/language-page/Rust.webp'
import SQL from 'assets/main/news/pages/language-page/SQL.webp'
import TypeScript from 'assets/main/news/pages/language-page/TypeScript.webp'
import { SxProps } from '@mui/material';

export interface ILanguageBottomSection {
  title: string;
  primaryUses: string;
  adjustedJobPostings: string;
  averageSalary: string;
  useWithCaution?: string;
  learningDifficulty: string;
  developerSentiment: string;
  futureOutlook: string;
  description: string[];
  careerOpportunities?: {
    title: string;
    items: string[][];
  }[];
  img:{
    src:string
    imgSx:SxProps
  }
}

export const languageBottomSection: ILanguageBottomSection[] = [
  {
    title: '1. Python',
    primaryUses:
      'Machine learning, AI, web development, data science, automation, scripting, app development.',
    adjustedJobPostings: '~85,000',
    averageSalary: '$121,900',
    useWithCaution:
      'PHP, which has seen declining popularity, but still underpins many legacy systems.',
    learningDifficulty: 'Beginner-Friendly',
    developerSentiment: '65.52% of developers love Python.',
    futureOutlook:
      'Widely used across various industries, with continuous growth, particularly in AI and data science.',
    description: [
      'Python, like a fine wine, has only improved with age. Despite being more than 30 years old, its popularity shows no signs of waning. This highly versatile language is a favorite among both novices and seasoned developers. Its English-like syntax makes it approachable for beginners, allowing them to pick up the basics quickly and dive into real projects with ease.',
      "But don't be fooled — Python is not just for beginners. Its use in complex machine learning algorithms and AI projects solidifies its place in the cutting-edge tech landscape.",
    ],
    careerOpportunities: [
      {
        title: '',
        items: [
          [
            'Data Scientist:',
            'Average salary $122,700'
          ],
          [
            'Data Analyst:',
            'Average salary $82,600'
          ],
          [
            'Data Engineer:',
            'Average salary $129,700'
          ],
          [
            'Machine Learning Engineer:',
            'Average salary $128,800'
          ],
        ],
      },
      {
        title: 'Python expertise opens doors in fast-growing sectors that reward innovation and analytical prowess.',
        items: [
          [
            'Main Use Cases:',
            'Machine learning, artificial intelligence, data science, web development, automation.'
          ],
          [
            'Pros:',
            'Easy to learn, versatile, high job market demand, good salaries.'
          ],
          [
            'Cons:',
            ' Slower than some other languages in terms of performance.'
          ],
        ],
      },
    ],
    img:{
      src:Python,
      imgSx:{
        top:'92px',
        right:{xs:'8px', md: '-10px'}
      }
    }
  },
  {
    title: '2. SQL',
    primaryUses: 'Data analysis, database management, business reporting, data queries, backend development (with other languages).',
    adjustedJobPostings: '~64,600',
    averageSalary: '$109,400',
    useWithCaution: 'PHP, which has seen declining popularity, but still underpins many legacy systems.',
    learningDifficulty: 'Beginner-Friendly',
    developerSentiment: '64.26% of developers love SQL',
    futureOutlook: 'A staple of data management since the 1970s, SQL remains indispensable.',
    description: ['SQL might not have the glamour of more modern languages, but it’s essential in any data-centric role. Its straightforward nature makes it easy to learn, yet powerful enough to analyze and manipulate complex data sets. Whether you’re working on data extraction or business analytics, SQL provides the foundation needed to manage relational databases efficiently. Even as automated tools and AI simplify data queries, understanding the core principles of SQL is critical.'],
      img:{
      src:SQL,
      imgSx:{
        top:'95px',
        right:{xs:'8px', md: '360px'}
      }
    }
  },
  {
    title: '3. TypeScript',
    primaryUses: 'Web apps, mobile apps, DevOps, IoT devices, game development, desktop apps, server applications, websites.',
    adjustedJobPostings: '~7,800',
    averageSalary: '$129,300',
    learningDifficulty: 'Moderately Straightforward',
    developerSentiment: ' 71.7% of developers love TypeScript.',
    futureOutlook: 'Backed by Microsoft and popular for large-scale applications.',
    description: ['TypeScript is the enhanced version of JavaScript, adding static typing to ensure better code organization and fewer bugs. Developers love its ability to make large codebases more manageable and maintainable, which is crucial for complex applications. While job postings are fewer compared to other top languages, TypeScript offers a high average salary, reflecting the demand for specialized skills.'],
    img:{
      src:TypeScript,
      imgSx:{
        top:'82px',
        right:{xs:'8px', md: '28px'}
      }
    }
  },
  {
    title: '4. Rust',
    primaryUses: 'Game development, web development, VR, operating systems, Web3, performance-critical systems, low-level programming, embedded ',
    adjustedJobPostings: '~22,900',
    averageSalary: '$109,900',
    learningDifficulty: 'Moderately Straightforward',
    developerSentiment: '84.66% of developers love Rust',
    futureOutlook: 'Known for safety and performance, Rust is gaining momentum in high-demand sectors.',
    description: ['Rust is a rising star with an impressive focus on safety and performance. It consistently ranks as the most loved language, thanks to its modern syntax and strict memory management rules that prevent many common bugs. While not as beginner-friendly as Python, Rust’s capabilities make it a top choice for performance-intensive projects in innovative fields like Web3 and VR.'],
      img:{
      src:Rust,
      imgSx:{
        top:'85px',
        right:{xs:'8px', md: '374px'}
      }
    }
  },
  {
    title: '5. JavaScript',
    primaryUses: 'Web apps, mobile apps, DevOps, IoT devices, game development, desktop apps, server applications, data visualization, web sockets, websites.',
    adjustedJobPostings: '~41,900',
    averageSalary: '$106,600',
    learningDifficulty: 'Beginner-Friendly',
    developerSentiment: '57.83% of developers love JavaScript.',
    futureOutlook: 'Remains essential for web development and beyond.',
    description: ['JavaScript is the backbone of web development. It’s the go-to language for building interactive and dynamic web pages and apps. With frameworks like React, Angular, and Node.js, JavaScript’s capabilities have expanded into mobile and server-side development. While it may not top developer satisfaction surveys, its ubiquity and versatility make it an indispensable skill.'],
    careerOpportunities: [
      {
        title: '',
        items: [
          [
            'Front-End Developer:',
            'Average salary $101,500'
          ],
          [
            'Back-End Developer:',
            'Average salary $100,700'
          ],
          [
            'Full-Stack Developer:',
            'Average salary $117,800'
          ]
        ],
      },
    ],
      img:{
      src:JavaScript,
      imgSx:{
        top:'201px',
        right:{xs:'8px', md: '119px'}
      }
    }
  },
  {
    title: '6. C++',
    primaryUses: ' Game development, VR, robotics, software, operating systems, complex computations, mobile development, system programming, embedded systems, performance-critical applications.',
    adjustedJobPostings: '~46,400',
    averageSalary: '$120,200',
    learningDifficulty: 'Challenging',
    developerSentiment: '49.77% of developers love C++.',
    futureOutlook: 'Critical for high-performance systems and real-time applications.',
    description: ['C++ is known for its power and efficiency. Its low-level capabilities allow developers to interact closely with computer hardware, making it ideal for applications where performance is key. While more challenging to learn, its mastery can lead to opportunities in game development, robotics, and systems programming where efficiency is non-negotiable.'],

        img:{
      src:CPlus,
      imgSx:{
        top:'90px',
        right:{xs:'8px', md: '492px'}
      }
    }},
  {
    title: '7. Java',
    primaryUses: 'Enterprise applications, web apps, Android development, largescale systems, e-commerce, fintech, big data.',
    adjustedJobPostings: '~54,700',
    averageSalary: ' $117,900',
    learningDifficulty: 'Moderately Straightforward',
    developerSentiment: '44.1% of developers love Java.',
    futureOutlook: 'A mainstay of software development, backed by Oracle.',
    description: ['Java has long been a pillar of enterprise software and large-scale systems. Its portability — “write once, run anywhere” — and object-oriented approach make it a reliable choice for companies needing scalable, cross-platform solutions. Although its popularity among developers may fluctuate, Java continues to be a cornerstone language.'],
      img:{
      src:Java,
      imgSx:{
        top:'62px',
        right:{xs:'8px', md: '62px'}
      }
    }
  },
  {
    title: '8. Go (Golang)',
    primaryUses: 'Command-line tools, GUI applications, system and network programming, web apps, web servers, microservices, cloud applications.',
    adjustedJobPostings: '~33,700',
    averageSalary: ' $121,100',
    learningDifficulty: 'Moderately Straightforward',
    developerSentiment: ' 62.45% of developers love Go.',
    futureOutlook: 'Rapidly adopted by enterprises, supported by Google.',
    description: ['Go was created for simplicity and performance, making it perfect for modern cloud applications and server-side development. With strong concurrency support, Go is particularly suited for scalable network applications. Its clean syntax appeals to developers looking for straightforward coding without sacrificing efficiency.'],
          img:{
      src:Go,
      imgSx:{
        top:'138px',
        right:{xs:'8px', md: '370px'}
      }
    }},
  {
    title: '9. C#',
    primaryUses: 'Enterprise applications, web development, mobile apps, game development, VR, desktop applications ',
    adjustedJobPostings: '~33,500',
    averageSalary: '$120,200',
    learningDifficulty: 'Moderately Straightforward',
    developerSentiment: '62.87% of developers love C#.',
    futureOutlook: 'A staple in the Microsoft ecosystem with strong future prospects.',
    description: ['C# is a versatile language integral to the .NET framework, making it perfect for developing Windows-based applications. It is also popular in game development due to Unity’s adoption. Supported by Microsoft, C# remains an excellent choice for those aiming for careers in enterprise or game development.'],
      img:{
      src:CSharp,
      imgSx:{
        top:'62px',
        right:{xs:'8px', md: '75px'}
      }
    }
  },
  {
    title: '10. Ruby',
    primaryUses: 'Web development, web servers, DevOps, scripting, automation, prototyping.',
    adjustedJobPostings: '~7,600',
    averageSalary: '$134,200',
    learningDifficulty: 'Beginner-Friendly',
    developerSentiment: '47.69% of developers love Ruby.',
    futureOutlook: 'Its popularity has declined but retains a dedicated following.',
    description: ['Ruby, especially when paired with Ruby on Rails, is loved for its simplicity and productivity in web development. While not as mainstream as it once was, Ruby is still appreciated for rapid prototyping and ease of use, making it a solid choice for startups and smaller-scale projects.'],
      img:{
      src:Ruby,
      imgSx:{
        top:'74px',
        right:{xs:'8px', md: '389px'}
      }
    }
  },
  {
    title: '11. Kotlin',
    primaryUses: 'Android development, server-side applications, cross-platform development.',
    adjustedJobPostings: '~2,200',
    averageSalary: '$127,200',
    learningDifficulty: 'Moderately Straightforward',
    developerSentiment: '60.77% of developers love Kotlin',
    futureOutlook: 'The preferred language for Android, promoted by Google.',
    description: ['Kotlin has been embraced by the Android community for its modern features and full interoperability with Java. Its concise syntax helps reduce boilerplate code, speeding up development while enhancing code safety. Ideal for mobile developers seeking to create sleek, efficient apps.'],
      img:{
      src:Kotlin,
      imgSx:{
        top:'31px',
        right:{xs:'8px', md: '94px'}
      }
    }
  },
  {
    title: '12. PHP',
    primaryUses: 'Web development and web applications.',
    adjustedJobPostings: '~17,900',
    averageSalary: '$102,000',
    learningDifficulty: 'Beginner-Friendly',
    developerSentiment: '41.83% of developers love PHP',
    futureOutlook: 'Despite declining popularity, PHP still powers a significant portion of the web, largely due to platforms like WordPress.',
    description: ['While PHP is no longer the trendiest language on the block, it remains a crucial tool for web development, supporting many legacy systems and current web applications. Its ease of use and deep integration into web technologies make it a practical choice for server-side scripting. PHP’s widespread use means there’s a wealth of existing codebases and examples to learn from, ensuring that developers can build or maintain web solutions effectively. Although newer technologies are vying for attention, PHP’s established role means it continues to offer stable job opportunities for those willing to specialize.'],
      img:{
      src:PHP,
      imgSx:{
        top:'44px',
        right:{xs:'8px', md: '26px'}
      }
    }
  },
];

export const pageTags = [
  'ProgrammingLanguages',
  'BestLanguages2025',
  'Python',
  'JavaScript',
  'Rust',
  'GoLang',
  'TypeScript',
  'WebDevelopment',
  'MachineLearning',
  'CodingCareer',
  'DeveloperTips',
  'LearnToCode',
  'TechTrends',
  'FutureOfProgramming',
  'ITCareers',
  'SoftwareDevelopment',
  'CodingLife',
  'CareerGrowth',
  'DevCommunity',
  'ProgrammingTips'
]