import { useMemo } from 'react';

import { Theme, useMediaQuery } from '@mui/material';

interface MediaSize {
  isTablet: boolean;
  xs: boolean;
  smXl: boolean;
  xlUp: boolean;
  isDesktop: boolean;
  mdXl: boolean;
  smMD: boolean;


}

export const useMediaSize = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.only('sm'));
  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
  const smXl = useMediaQuery<Theme>((theme) => theme.breakpoints.between('sm', 'xl'));
  const smMD = useMediaQuery<Theme>((theme) => theme.breakpoints.between('sm', 'md'));
  const mdXl = useMediaQuery<Theme>((theme) => theme.breakpoints.between('md', 'xl'));
  const xlUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  return useMemo(
    (): MediaSize => ({
      isTablet,
      xs,
      smXl,
      xlUp,
      isDesktop,
      mdXl,
      smMD
    }),
    [isTablet, xs, smXl, xlUp, isDesktop]
  );
};
