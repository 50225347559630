import React, { useEffect, useRef, useState } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import FirstCard from 'components/main/first/FirstCard/FirstCard';

import { ReactComponent as WhiteArrow } from 'assets/common/FlyingButton/WhiteArrow.svg';

import { firstInfo } from './consts';

const FirstSection = () => {
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const videoSrc = '/bgVideo.webm';
  const handleVideoLoaded = async () => {
    setIsVideoReady(true);
    await videoRef.current?.play();
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadeddata', handleVideoLoaded);

      return () => {
        videoElement.removeEventListener('loadeddata', handleVideoLoaded);
      };
    }
  }, []);
  return (
    <Box
      id="first"
      className="first_section"
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          minHeight: '500px',
          height: '100vh',
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {!isVideoReady && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
              backgroundColor: theme.palette.black.main,
            }}
          ></Box>
        )}
        <Box
          component="video"
          ref={videoRef}
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: isVideoReady ? 0 : -1,
            opacity: isVideoReady ? 0.8 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
          src={videoSrc}
          autoPlay
          muted
          playsInline
          loop
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '60%', md: '50%' },
            left: '50%',
            transform: { xs: 'translate(-50%,-50%)', md: 'translate(-50%,-40%)' },
            maxWidth: '1290px',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '32px', md: '119px' },
            zIndex: 2,
          }}
        >
          <FlyingBall
            clsName="first_section_flying_ball"
            setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
            sx={{
              right: '0px',
              bottom: '-50px',
              display: { xs: 'none', md: isVideoReady ? 'flex' : 'none' },
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: '45px', md: '126px' },
              fontWeight: 800,
              lineHeight: { xs: '52.2px', md: '146px' },
              textAlign: 'left',
              color: theme.palette.text.primary,
              px: { xs: '16px', xl: 0 },
            }}
          >
            Your
            <span style={{ color: theme.palette.orange.main }}> Trusted partner </span>
            for Digital Growth!
          </Typography>
          <Box
            className="first_section_cards"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: { xs: '16px', md: '30px' },
              justifyContent: {
                xs: 'center',
                md: 'start',
              },
              px: '16px',
              mt: '32px',
            }}
          >
            {firstInfo.map((item) => (
              <FirstCard key={item.id} firstInfo={item} />
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              px: '16px',
            }}
          >
            <CustomButton
              onClick={() => {
                setIsOpenGotAProjectDrawer(true);
              }}
              sx={{
                margin: 'auto',
                mt: '30px',
                height: '56px',
                borderRadius: '30px',
                backgroundColor: theme.palette.black.main,
                color: theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                width: '100%',
                maxWidth: '500px',
              }}
            >
              <>
                Free consultation
                <WhiteArrow
                  stroke={theme.palette.text.primary}
                  style={{
                    maxWidth: '24px',
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default FirstSection;
