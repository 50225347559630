import LinkedInPng from 'assets/common/LinkedIn.png';
import TiktokPng from 'assets/common/Tiktok.png';
import YoutubePng from 'assets/common/Youtube.png';

interface IPage {
  text: string;
  link: string;
}

interface Social {
  link: string;
  icon: string;
}

interface Policies {
  link: string;
  title: string;
}

export const appbarLinks: IPage[] = [
  { text: 'Services', link: 'services' },
  { text: 'Process', link: 'process' },
  { text: 'Portfolio', link: 'portfolio' },
];
export const appbarDropdownLinks: IPage[] = [
  { text: 'Testimonials and Team', link: 'testimonials' },
  { text: 'Who we are?', link: 'whoweare' },
  { text: 'Awards and Trends', link: 'awards' },
  { text: 'Blog and news', link: 'news' },
];

export const sections = [
  { id: 'first', title: 'Home' },
  { id: 'services', title: 'Services' },
  { id: 'howdowedo', title: 'How Do We Do IT' },
  { id: 'process', title: 'Process' },
  { id: 'portfolio', title: 'Portfolio' },
  { id: 'testimonials', title: 'Testimonials and Team' },
  { id: 'whoweare', title: 'Who we are?' },
  { id: 'awards', title: 'Awards and Trends' },
  { id: 'news', title: 'Blog and news' },
];

export const popupBurgerLinks = [
  { link: 'services', title: 'Services' },
  { link: 'process', title: 'Process' },
  { link: 'portfolio', title: 'Portfolio' },
  { link: 'whoweare', title: 'Who we are?' },
  { link: 'testimonials', title: 'Testimonials and Team' },
  { link: 'awards', title: 'Awards and Trends' },
  { link: 'news', title: 'Blog and news' },
];

export const social: Social[] = [
  {
    link: 'https://www.youtube.com/@WeCanDevIT',
    icon: YoutubePng,
  },
  {
    link: 'https://www.tiktok.com/@wecandevelopit',
    icon: TiktokPng,
  },
  {
    link: 'https://www.linkedin.com/in/vitaliyopryshko/',
    icon: LinkedInPng,
  },
];
export const policies: Policies[] = [
  {
    link: '/privacy',
    title: 'Privacy Policy',
  },
  {
    link: '/terms',
    title: 'Terms of Service',
  },
  {
    link: '/cookies',
    title: 'Cookies Settings',
  },
];
