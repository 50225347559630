import { SxProps } from '@mui/material';

import ProgrammingLanguages from 'assets/main/news/ProgrammingLanguages.webp';
import CheckOut from 'assets/main/news/CheckOut.webp';
import CleverWallet from 'assets/main/news/CleverWallet.webp';
import GlobalGamers from 'assets/main/news/GlobalGamers.webp';
import ReconnectAI from 'assets/main/news/ReconnectAI.webp';
import Today from 'assets/main/news/Today.webp';
import CheckOutFirstSection from 'assets/main/news/pages/CheckOutFirstSection.webp';
import CleverWalletFirstSection from 'assets/main/news/pages/CleverWalletFirstSection.webp';
import CleverWalletSecondSection from 'assets/main/news/pages/CleverWalletSecondSection.webp';
import GlobalGamersFirstSection from 'assets/main/news/pages/GlobalGamersFirstSection.webp';
import GlobalGamersSecondSection from 'assets/main/news/pages/GlobalGamersSecondSection.webp';
import ReconnectAIFirstSection from 'assets/main/news/pages/ReconnectAIFirstSection.webp';
import ReconnectAISecondSection from 'assets/main/news/pages/ReconnectAISecondSection.webp';
import TodayFirstSection from 'assets/main/news/pages/TodayFirstSection.webp';
import TodaySecondSection from 'assets/main/news/pages/TodaySecondSection.webp';

export interface IPageNewsSection {
  pageSubtitle?: string;
  pageDescription?: string[];
  pageDescriptionItems?: string[];
  pageDescriptionItemsBold?: string[][];
  pageDescriptionItemsWithoutDot?: string[];
  pageImage?: { image: string; skeletonHeight: string };
  pageVideo?: string;
  orangeText?: string;
  footnote?: string;
}

export interface INews {
  id: number;
  image: string;
  title: string;
  createdAt: string;
  newsType: string;
  imageBoxSX?: SxProps;
  mainBoxSX?: SxProps;
  pageTags?: string[];
  page?: IPageNewsSection[];
  pageUrl?: { title: string; link: string };
}

interface ITags {
  id: number;
  title: string;
}

export const tags: ITags[] = [
  {
    id: 1,
    title: 'All',
  },
  {
    id: 2,
    title: 'News',
  },
  {
    id: 3,
    title: 'Product',
  },
  {
    id: 4,
    title: 'IT development',
  },
];

export const news: INews[] = [
  {
    id: 1,
    title: 'Best Programming Languages To Learn In 2025',
    newsType: 'Tech News',
    createdAt: 'November 10,2024',
    image: ProgrammingLanguages,
    imageBoxSX: { width: '100%', height: { xs: '186px', md: '290px' } },
    pageTags: [
      'ProgrammingLanguages',
      'BestLanguages2025',
      'Python',
      'JavaScript',
      'Rust',
      'GoLang',
      'TypeScript',
      'WebDevelopment',
      'MachineLearning',
      'CodingCareer',
      'DeveloperTips',
      'LearnToCode',
      'TechTrends',
      'FutureOfProgramming',
      'ITCareers',
      'SoftwareDevelopment',
      'CodingLife',
      'CareerGrowth',
      'DevCommunity',
      'ProgrammingTips'
    ],
    page: [
      {
        pageImage: { image: ProgrammingLanguages, skeletonHeight: '912px' },
      },
    ],
  },
  {
    id: 2,
    title: 'Celebrating a Decade of Innovation with We Can Develop IT',
    newsType: 'IT Development',
    createdAt: 'October 25,2024',
    image: Today,
    imageBoxSX: { width: '100%', height: { xs: '186px', md: '290px' } },
    pageTags: [
      'WeCanDevelopIT',
      '10Years',
      'DigitalInnovation',
      'YourPartnerInGrowth',
      'CelebratingSuccess',
      'IT development',
      'TechInnovation',
      'BlueprintDesign',
      'SiteUpdate',
      'UserExperience',
    ],
    page: [
      {
        pageImage: { image: TodayFirstSection, skeletonHeight: '47.8294%' },
      },
      {
        pageSubtitle:
          'This year marks a special milestone for We Can Develop IT — ten years of  transforming ideas into digital solutions that power businesses worldwide.',
      },
      {
        pageDescription: [
          'Our  journey has been one of growth, discovery, and partnership. As we unveil our  refreshed brand and newly reimagined website, weʼre proud to look back on the  achievements that brought us here and excited to look forward to whatʼs next.',
        ],
      },
      {
        pageDescriptionItems: [
          'Our story began with a vision: to help companies achieve digital success through  tailored software solutions. From the very first project to the hundreds that  followed, we have remained focused on quality, adaptability, and collaboration.  Over these ten years, our expertise has expanded, and so has our portfolio. With  over 1,200 completed projects, We Can Develop IT has become a trusted name in  fields ranging from healthcare and finance to e-commerce, blockchain, and  artificial intelligence.',
          'Each project has been an opportunity to innovate. Weʼve crafted e-commerce  platforms that bring security and ease to B2B and B2C transactions, helping our  clients build lasting connections with their customers. Our CRM and ERP systems  have empowered businesses to streamline their processes, making growth  smoother and more efficient.',
          'Our success is not only measured in projects but also in the recognition weʼve earned along the way. Over the years, weʼve stood out in the tech community as winners of industry hackathons like DApp Hack 1.0 and Intema Metaverse Accelerator 2023. Our solutions have been recognized on global platforms, placing us among the Top 100 Metaverse Innovators and earning us a second place win at the NEAR Blockchain Hackathon. With more than 30 hackathons under our belt, weʼve shown that our commitment to innovation is as strong as ever.',
          'And as we grew, so did our support for our clients. Our IT support and maintenance services were born from a simple commitment: that no system we build should stand still. From regular audits to proactive modernization, we ensure that our clientsʼ systems are secure, efficient, and ready for the future.',
        ],
      },
      {
        pageDescription: [
          'This anniversary is not just a celebration of our achievements; itʼs a thank you to  our clients and partners who have trusted us with their visions. We Can Develop  IT is more than a name — itʼs a promise to bring expertise, care, and creativity to  every project we touch.',
          'Explore our new website and discover how weʼre evolving to meet the needs of the future. Hereʼs to the next decade of innovation, and hereʼs to building it together.',
        ],
      },
      {
        pageImage: { image: TodaySecondSection, skeletonHeight: '58.139%' },
      },
      {
        orangeText: 'Exclusive Anniversary Discounts!',
      },
      {
        pageDescription: [
          'In celebration of our 10-year journey, weʼre offering special discounts on our  services:',
        ],
      },
      {
        pageDescriptionItemsBold: [
          [
            '10% off',
            'for all new clients — a perfect opportunity to see how our expertise  can elevate your business.',
          ],
          ['20% off', 'for our returning clients as a thank you for your ongoing partnership.'],
        ],
      },
      {
        pageDescription: [
          'Donʼt miss this chance to start something extraordinary with We Can Develop IT.  Visit our website to claim your discount and letʼs bring your ideas to life!.',
        ],
      },
      {
        footnote:
          ' The promotion is valid for the first 6 months of development and until the end of 2024',
      },
    ],
  },
  {
    id: 3,
    title: 'Reconnect AI: Bridging the Past and  Present with Interactive AI Avatars',
    newsType: 'Artificial Intelligence',
    createdAt: 'September 15,2024',
    image: ReconnectAI,
    imageBoxSX: { width: '100%', height: { xs: '186px', md: '290px' } },
    pageTags: [
      'ReconnectAI',
      'AIAvatars',
      'TechInnovation',
      'DigitalLegacy',
      'HistoricalFigures',
      'EmotionalSupport',
      'CustomerServiceAI',
      'AptosHackathon',
      'CustomAI',
      'UserExperience',
    ],
    pageUrl: { title: 'https://reconnectai.org/', link: 'https://reconnectai.org/' },
    page: [
      {
        pageImage: { image: ReconnectAIFirstSection, skeletonHeight: '47.8294%' },
      },
      {
        pageSubtitle:
          'Reconnect AI is a custom-developed startup that uses advanced artificial intelligence to create interactive avatars of past celebrities, historical figures, and even deceased relatives.',
      },
      {
        pageDescription: [
          'This project provides users with unique ways to  reconnect with the past, offering emotional support, educational tools, and  entertainment through AI-driven conversations.',
        ],
      },
      {
        pageDescriptionItems: [
          'Reconnect AI offers various use cases. Users can interact with avatars of  celebrities, gaining insights into the lives and works of actors, musicians, or  authors. Historical figures come to life, becoming active participants in education:  schools and universities can use these avatars for interactive history lessons,  while museums can create immersive cultural programs',
          'For families coping with  loss, the platform provides a deeply personal experience, allowing users to  interact with avatars of their deceased relatives, preserving their voices and  memories for emotional support and family bonding',
        ],
      },
      {
        pageImage: { image: ReconnectAISecondSection, skeletonHeight: '37.2868%' },
      },
      {
        pageDescriptionItems: [
          'Businesses can also benefit from the platform by using AI avatars for customer  support or virtual sales roles, enhancing the customer experience and personalizing interactions',
          'To accelerate development and demonstrate the potential of Reconnect AI, we  participated in Aptos Collision Hack. We showcased how our platform seamlessly  integrates into new ecosystems, addressing key challenges like simplifying  technology adoption, enhancing customer service, and preserving personal  memories',
          'Reconnect AI offers flexible customization options. Users can communicate with  avatars via text or voice, receiving responses in text, audio, or video format.  Additionally, avatars can be personalized by uploading photos, voices, and  tailoring the conversation style, making the interaction as unique and customized  as possible',
        ],
      },
      {
        pageDescription: [
          'Reconnect AI transforms how people interact with the digital world, bringing  together the past and present, helping people learn, remember, and find comfort  through AI-powered connections.',
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Check Out Our New YouTube Channel for  Fun and IT News!',
    newsType: 'Tech News',
    createdAt: 'July 25,2024',
    image: CheckOut,
    imageBoxSX: { width: '100%', height: { xs: '186px', md: '290px' } },
    pageUrl: { title: 'Our YouTube Channel', link: 'https://www.youtube.com/@WeCanDevIT' },
    pageTags: [
      'YouTubeChannel',
      'ITNews',
      'TechShorts',
      'FunnyVideos',
      'TechUpdates',
      'ITHumor',
      'JoinUs',
      'DigitalGrowth',
      'ShortsContent',
      'SubscribeNow',
    ],
    page: [
      {
        pageImage: { image: CheckOutFirstSection, skeletonHeight: '47.8294%' },
      },
      {
        pageSubtitle:
          " We've launched our very own YouTube channel, where we cover the latest IT  news and more in Shorts format.",
      },
      {
        pageDescription: [
          "Alongside that, we create funny videos just for  fun and run the channel for the pure joy of it. Itʼs a space where we can share  what inspires and entertains us. If you're interested, join us and watch how we  grow and evolve!",
          'Subscribe to stay updated:',
        ],
      },
    ],
  },
  {
    id: 5,
    title: 'Clever Wallet: Your All-in-One Solution for  Financial Management',
    newsType: 'Fintech Solution',
    createdAt: 'June 25,2024',
    image: CleverWallet,
    imageBoxSX: { width: '100%', height: { xs: '186px', md: '290px' } },
    pageTags: [
      'Finance',
      'FintechSolution',
      'MultiCurrencyWallet',
      'StartupTools',
      'EnterpriseFinance',
      'DigitalWallet',
      'CryptoIntegration',
      'PaymentSolutions',
      'BusinessAutomation',
      'SeamlessIntegration',
    ],
    pageUrl: { title: 'https://www.cleverwallet.app/', link: 'https://www.cleverwallet.app/' },
    page: [
      {
        pageImage: { image: CleverWalletFirstSection, skeletonHeight: '47.8294%' },
      },
      {
        pageSubtitle:
          'Clever Wallet is our proprietary product and project designed to solve financial  challenges of any complexity. ',
      },
      {
        pageDescription: [
          'Weʼve developed a platform that enables users to  manage multi-currency wallets, conduct financial operations, and seamlessly  integrate with a clientʼs business processes. Our solution is fully customizable:  clients select the necessary modules, and we create a tailored bundle that  integrates smoothly with their systems via SDK. This allows businesses to flexibly  and efficiently manage assets while streamlining financial workflows',
        ],
      },
      {
        pageDescriptionItems: [
          'For startups, Clever Wallet provides out-of-the-box solutions that enable a fast  launch and immediate use of powerful financial tools. Our product helps new  companies automate operations and manage assets without requiring complex  technical integrations. For established businesses, we offer scalable solutions that  easily adapt to existing infrastructure, optimizing financial flows and integrating  various payment systems and currencies',
          "Among Clever Wallet's features are multi-currency wallet creation, fund transfers,  and automated operations. The platform supports both fiat and cryptocurrencies,  making it ideal for modern financial needs. Users can create wallets for any asset  type, from traditional currencies to unique value units like loyalty points. The  technology integrates with popular payment systems such as Stripe, PayPal, and  Wise, as well as blockchain solutions for handling cryptocurrencies",
        ],
      },
      {
        pageImage: { image: CleverWalletSecondSection, skeletonHeight: '42.7131%' },
      },
      {
        pageDescriptionItems: [
          'Clever Wallet is already actively used in several successful projects. For example,  Tixnet is an innovative ticketing system for event organizers. It allows users to  sell, manage, and organize events, maximizing sales efficiency. Tixnet stands out  as the first global multi-currency and multilingual ticketing platform',
          'Another example is Shopiview, a service that enables users to leave paid product  reviews on various marketplaces. Companies receive honest and high-quality  feedback, while users have the opportunity to earn money, creating a transparent  and rewarding ecosystem for both parties',
          'We have also integrated Clever Wallet into the Meta Ads project, an advanced  advertising platform for metaverses and virtual worlds. The platform provides  precise targeting in AR, VR, and MR environments, offering solutions for  performance marketing and analytics',
        ],
      },
      {
        pageDescription: [
          'Our technology has already proven its effectiveness, and we are ready to adapt it  to meet the unique needs of your business.',
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'Global Gamers Challenge Hack: How We  Created a Game to Help the Planet',
    newsType: 'Hackathon Project',
    createdAt: 'May 15,2024',
    image: GlobalGamers,
    imageBoxSX: { width: '100%', height: { xs: '186px', md: '290px' } },
    pageTags: [
      'FlutterDev',
      'GameDevelopment',
      'GlobalGamersChallengeр',
      'SustainableGaming',
      'HedgehogEcoDash',
      'EnvironmentalAwareness',
      'SaveThePlanet',
      'WasteSorting',
      'HackathonProject',
    ],
    page: [
      {
        pageImage: { image: GlobalGamersFirstSection, skeletonHeight: '47.8294%' },
      },
      {
        pageSubtitle:
          'When we saw the announcement for the Global Gamers Challenge, we didnʼt  hesitate to join.',
      },
      {
        pageDescription: [
          'For us, it wasnʼt just about taking on the challenge of creating a  new game but also contributing to sustainable development and environmental  awareness. As a team of software developers and nature lovers, we felt that we  could do something meaningful for the planet, which desperately needs protection  from pollution. The idea of creating a game that teaches children how to sort waste had been on  our minds for a while, and thanks to this hackathon, we finally brought it to life',
          'The Global Gamers Challenge is an 8-week contest for creating sustainable  games, organized by Flutter and Global Citizen. Inspired by the mission of the UN  to protect the planet through play, we eagerly took part to show how games can  inspire positive environmental change. For us, this was more than just a contest — it was an opportunity to make a difference for the planet',
        ],
      },
      {
        pageImage: { image: GlobalGamersSecondSection, skeletonHeight: '36.3565%' },
      },
      {
        pageDescription: [
          'We are hiking enthusiasts, and over the years, weʼve noticed increasing pollution  in our favorite spots. Despite our efforts to clean up after ourselves and others,  the global battle against litter requires more attention. This led us to the idea of  creating a game that teaches kids the importance of waste sorting and  5 We Can Develop IT Blog encourages respect for the environment. We believe that through games, we can  instill good habits and raise a generation with strong environmental values',
          "Hedgehog Eco Dash is an exciting mobile strategy game where players help  adorable hedgehogs carry fruits and sort trash along the way. The game starts  with simple levels, but as players progress, obstacles in the form of unsorted trash  block the hedgehogs' path. Players must not only assist the hedgehogs but also  correctly sort the trash to clear the way",
          'We chose hedgehogs as the main characters because they symbolize the  environmental problems caused by pollution. As players advance through the  game, the levels become more challenging, requiring more attention and strategic  thinking. But with every level completed, the players take one step closer to a  cleaner world',
          ' In the game, players guide the hedgehogs as they gather fruits and navigate  obstacles. The hedgehogs can pass items to one another, sort the trash, and  deliver resources to designated points. The better you sort the waste, the more  points you earn. But be careful — sorting incorrectly or delivering trash to the  wrong location results in penalties. Your goal is to help as many hedgehogs as  possible reach their destinations while scoring as many points as you can',
          'We built the game using Dart, Flutter, and the Flame game engine. While we were  familiar with Flutter and Dart, this was our first time working with Flame, which  presented a significant challenge. Flame is a powerful tool, but the lack of a visual  editor for development made the process more difficult. However, we managed to  overcome these challenges and created 16 unique levels with mechanics that  make the game engaging for both beginners and experienced players',
        ],
      },
      { pageVideo: 'https://www.youtube.com/embed/r9ZEmWgV3gw' },
      {
        pageDescription: [
          'Our biggest challenge was Flameʼs lack of a familiar development editor like Unity  or Unreal Engine. We faced difficulties in adding game objects, but with the help  of documentation and our technical expertise, we solved the problems',
        ],
      },
      {
        pageDescriptionItemsWithoutDot: ['We are proud of what we accomplished:'],
      },
      {
        pageDescriptionItems: [
          'Flexible game mechanics that keep the game fun for both new and  experienced players. ',
          'A multiplayer mode that enhances the gameplay and attracts a wider  audience. ',
          'Unique level designs — 16 different stages, each with its own tasks and  mechanics.',
        ],
      },
      {
        pageDescription: [
          'We implemented animations, character movement logic, and achievement  systems. We also integrated popular libraries like GetIt for dependency injection  and Shared Preferences for local storage. Although we initially planned to use the BLoC architecture, we decided to focus on delivering the game concept and  postponed its implementation',
          'Even though we didnʼt win the hackathon, the experience was incredibly valuable  for us. Weʼre proud to have taken a step toward creating a game that can make the  world a little better',
          'If youʼre interested in our project or have an idea for a software development  project, weʼd be happy to offer a free consultation. Our team is ready to bring your  ideas to life with the highest standards of development.',
        ],
      },
    ],
  },
];
