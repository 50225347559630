import React, { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { IProcess } from 'pages/main/process/consts';

interface IProps {
  process: IProcess;
}

const ProcessCard: FC<IProps> = ({ process }) => {
  const { icon: Icon, title, firstDescription, secondDescription, id, percent } = process;
  return (
    <Box
      className={`process_card_${id}`}
      sx={{
        maxWidth: '473px',
        width: '100%',
        height: '164px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: percent + '%',
        zIndex: id,
        transition: 'all 0.25s linear',
        backgroundColor: theme.palette.black.main,
      }}
    >
      <Box
        sx={{
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          minHeight: '64px',
          width: '100%',
          gap: '8px',
          backgroundColor: '#272727',
          boxSizing: 'border-box',
          p: '12px 16px',
          borderRadius: '8px',
        }}
      >
        <Icon className={`process_card_title_${id}`} />
        <Typography
          className={`process_card_title_${id}`}
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '48px',
            textAlign: 'left',
            color: theme.palette.text.primary,

          }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          className={`process_card_description_${id}`}
          sx={{
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '36px',
            textAlign: 'left',
            color: theme.palette.grey[600],
            mb: '24px',
            opacity: 0,
            transition: 'all 0.1s linear',
            backgroundColor: theme.palette.black.main,

          }}
        >
          {firstDescription}
          <br />
          <br />
          {secondDescription}
        </Typography>
      </Box>
    </Box>
  );
};
export default ProcessCard;
