import React, { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { IProcess } from 'pages/main/process/consts';

interface IProps {
  process: IProcess;
}

const ProcessCard: FC<IProps> = ({ process }) => {
  const { icon: Icon, title, firstDescription, secondDescription, id, image } = process;
  return (
    <Box
      className={`process_card_${id}`}
      sx={{
        width: '343px',
        height: '572px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.black.main,
        p:'16px',
        borderRadius: '20px',
      }}
    >
      <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        gap:'8px',
        backgroundColor: theme.palette.black.main,
      }}
      >
      <Box
        sx={{
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '8px',
          backgroundColor: '#272727',
          boxSizing: 'border-box',
          p: '8px 16px',
          borderRadius: '8px',
        }}
      >
        <Icon/>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
            textAlign: 'left',
            color: theme.palette.text.primary,

          }}
        >
          {title}
        </Typography>
      </Box>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            color: theme.palette.grey[600],
          }}
        >
          {firstDescription}
          <br />
          <br />
          {secondDescription}
        </Typography>
      </Box>
      <Box
        component="img"
        src={image}
        sx={{
          width: '311px',
          height: 'auto',
          borderRadius: '20px',
        }}
      />
    </Box>
  );
};
export default ProcessCard;
