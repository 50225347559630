import * as React from 'react';
import { Dispatch, FC, SetStateAction } from 'react';
import { theme } from 'theme';

import { Box, Grow, IconButton, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { IPopupInfo } from 'components/common/GotAProject';

import { ReactComponent as Close } from 'assets/common/Close.svg';

import { CustomButton } from '../Button';

interface IProps extends DialogProps {
  open: boolean;
  description: string;
  onClose: React.Dispatch<React.SetStateAction<IPopupInfo>>;
  setIsOpenGotAProjectDrawer: Dispatch<SetStateAction<boolean>>;
  title: string;
}

const SendMessagePopup: FC<IProps> = ({
  open,
  title,
  description,
  onClose,
  setIsOpenGotAProjectDrawer,
}) => {
  return (
    <Dialog
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 300 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={open}
      onClose={() => {
        onClose((prevState) => ({ ...prevState, open: false }));
        setIsOpenGotAProjectDrawer(false);
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: '447px',
          width: '100%',
          minHeight: '168px',
          boxSizing: 'border-box',
          display: 'flex',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          alignItems: 'center',
          m: 0,
          p: '12px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          maxWidth: '423px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.text.primary,
            borderRadius: '20px',
            maxWidth: '363px',
            width: '100%',
            minHeight: '168px',
            p: '24px',
            gap: '24px',
            boxSizing: 'border-box',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'left',
              color: theme.palette.text.secondary,
            }}
          >
            {title}
            <br />
            {description}
          </Typography>
          <CustomButton
            onClick={() => {
              onClose((prevState) => ({ ...prevState, open: false }));
              setIsOpenGotAProjectDrawer(false);
            }}
            sx={{
              width: '150px',
              height: '48px',
              borderRadius: '30px',
              backgroundColor: theme.palette.text.secondary,
              color: theme.palette.text.primary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            Ok
          </CustomButton>
        </Box>
        <IconButton
          onClick={() => {
            onClose((prevState) => ({ ...prevState, open: false }));
            setIsOpenGotAProjectDrawer(false);
          }}
          color="secondary"
          aria-label="close"
          sx={{
            alignSelf: 'start',
            width: '48px',
            height: '48px',
            backgroundColor: theme.palette.text.secondary,
            '&:hover': {
              backgroundColor: theme.palette.text.secondary,
            },
            '&:active': {
              backgroundColor: theme.palette.text.secondary,
            },
          }}
        >
          <Close
            stroke={theme.palette.text.primary}
            style={{
              cursor: 'pointer',
            }}
          />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default SendMessagePopup;
