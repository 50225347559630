import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import BottomLeftGear from 'assets/not-found/BottomLeftGear.webp';
import FewCircle from 'assets/not-found/FewCircle.webp';
import Grid from 'assets/not-found/Grid.webp';
import Man from 'assets/not-found/Man.webp';
import TopLeftCircle from 'assets/not-found/TopLeftCircle.webp';
import TopRightGear from 'assets/not-found/TopRightGear.webp';

import { CustomButton } from '../components/common/Button';
import { theme } from '../theme';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 437px)',
        backgroundColor: theme.palette.black.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        component="img"
        src={Grid}
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: 'auto',
        }}
      />

      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          height: '955px',
          position: 'relative',
          p: '102px 0 0 313px',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Box sx={{ zIndex: 10 }}>
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 800,
              lineHeight: '72px',
              textAlign: 'left',
              mb: '8px',
              color: theme.palette.text.primary,
            }}
          >
            OOPS!
          </Typography>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '36px',
              textAlign: 'left',
              mb: '28px',
              color: theme.palette.text.primary,
            }}
          >
            The page you are looking for does not exist
          </Typography>
          <CustomButton
            sx={{
              width: '166px',
              height: '56px',
              borderRadius: '30px',
              backgroundColor: theme.palette.orange.main,
              color: theme.palette.text.secondary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
            onClick={() => navigate('/')}
          >
            Go back home
          </CustomButton>
        </Box>
        <Box
          component="img"
          src={TopLeftCircle}
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            maxWidth: '215px',
            width: '100%',
            height: 'auto',
            zIndex: 10,
          }}
        />

        <Box
          component="img"
          src={BottomLeftGear}
          sx={{
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            maxWidth: '339px',
            width: '100%',
            height: 'auto',
            zIndex: 10,
          }}
        />
        <Box
          component="img"
          src={FewCircle}
          sx={{
            position: 'absolute',
            bottom: '103px',
            left: '0px',
            maxWidth: '1725px',
            width: '100%',
            height: 'auto',
            zIndex: 10,
          }}
        />
        <Box
          component="img"
          src={TopRightGear}
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            maxWidth: '443px',
            width: '100%',
            height: 'auto',
          }}
        />
        <Box
          component="img"
          src={Man}
          sx={{
            position: 'absolute',
            bottom: '42px',
            right: '314px',
            maxWidth: '941px',
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>
    </Box>
  );
};
