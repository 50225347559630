import { useMediaSize } from 'hooks/useDeviceDetector';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { INews } from 'pages/main/news/consts';

interface IProps {
  news: INews;
}

const NewsCard: FC<IProps> = ({ news }) => {
  const { image, title, createdAt, newsType, mainBoxSX, imageBoxSX, id, page } = news;
  const { isDesktop } = useMediaSize();
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`/news/${id}`)}
      sx={{
        maxWidth: { xs: '343px', md: '410px' },
        width: '100%',
        height: { xs: '343px', md: 'auto' },
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignSelf: 'flex-start',
        ...mainBoxSX,
      }}
    >
      <Box sx={{ overflow: 'hidden', borderRadius: '20px', ...imageBoxSX }}>
        <Box className="news_card_img">
          <Box
            component="img"
            src={isDesktop ? image : page?.[0]?.pageImage?.image}
            sx={{
              width: { xs: '343px', md: 'auto' },
              height: { xs: '186px', md: 'auto' },
              transition: 'transform 0.3s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: `scale(1.1)`,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '4px',
          color: '#A3A3A3',
          mb: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: { xs: '21px', md: '24px' },
            textAlign: 'left',
            position: 'relative',
          }}
        >
          {newsType}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: { xs: '21px', md: '24px' },
            textAlign: 'right',
            position: 'relative',
          }}
        >
          {createdAt}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '36px',
          textAlign: 'left',
          color: theme.palette.text.secondary,
          mb: { xs: '0px', md: '38px' },
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default NewsCard;
