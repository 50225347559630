import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';

import styles from './styles.module.scss';

import { Box, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import ServicesCard from 'components/main/services/ServicesCard';

import CircletPng from 'assets/main/services/Circlet.png';
import CircletLeft from 'assets/main/services/CircletLeft.png';
import CircletRight from 'assets/main/services/CircletRight.png';
import { ReactComponent as Services } from 'assets/main/services/Services.svg';
import TriangularPng from 'assets/main/services/Triangular.png';
import TriangularLeftPng from 'assets/main/services/TriangularLeft.png';
import TriangularRightPng from 'assets/main/services/TriangularRight.png';

import { services } from './consts';

const ServicesSection = () => {
  const { mdXl, xlUp } = useMediaSize();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        className="services_section"
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '2016px',
          py: { xs: '100px', md: '140px' },
          px: { xs: '16PX', md: '24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '38px', md: '16px' },
            left: '0px',
          }}
        >
          <Services
            id="services"
            style={{
              width: '100%',
              height: 'auto',
              zIndex: -1,
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '165px',
            right: '-50px',
            display: { xs: 'none', md: 'block', xxl: 'none' },
          }}
        >
          <Box
            component="img"
            src={TriangularPng}
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              zIndex: -1,
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '935px',
            left: '315px',
            display: { xs: 'none', md: 'block', xxl: 'none' },
          }}
        >
          <Box
            component="img"
            src={CircletPng}
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              zIndex: -1,
              transform: 'rotate(-33deg)',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            maxWidth: '1750px',
            width: '100%',
            top: '140px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10,
          }}
        >
          <FlyingBall
            clsName="services_section_flying_ball"
            setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
            sx={{ right: '-20px', top: '-100px' }}
          />
        </Box>
        <Box
          className="services_section_inner"
          sx={{
            position: 'relative',
            maxWidth: { xs: '343px', md: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '-140px',
              left: '-371px',
              display: { xs: 'none', xxl: 'block' },
            }}
          >
            <Box
              component="img"
              src={CircletLeft}
              sx={{
                position: 'absolute',
                bottom: '-95px',
                left: '182px',
                zIndex: 1,
                transform: 'rotate(-32deg)',
              }}
            />
            <Box
              component="img"
              src={CircletRight}
              sx={{
                position: 'absolute',
                bottom: '78px',
                left: '248px',
                transform: 'rotate(-32deg)',

                zIndex: -1,
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '22px',
              right: '-150px',
              display: { xs: 'none', xxl: 'block' },
            }}
          >
            <Box
              component="img"
              src={TriangularLeftPng}
              sx={{
                position: 'absolute',
                top: '15px',
                right: '75px',
                zIndex: -1,
              }}
            />
            <Box
              component="img"
              src={TriangularRightPng}
              sx={{
                position: 'absolute',
                top: '-14px',
                right: '-13px',
                zIndex: 1,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              mb: { xs: '32px', md: '54px' },
            }}
          >
            Services
          </Typography>
          <Box
            className={
              xlUp ? styles.gridWrapperXL : mdXl ? styles.gridWrapperSM : styles.gridWrapperXS
            }
          >
            {services.map((service) => (
              <Box
                key={service.id}
                className={`${styles[`services_card_${service.id}`]} ${styles.gridItem}  services_card_${service.id} services_card`}
              >
                <ServicesCard key={service.id} services={service} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesSection;
