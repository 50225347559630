import gsap from 'gsap';
import { boxHeight } from 'utils/boxHeight';

export const portfolioAnimation = (setActiveSection: (id: string | null) => void) => {
  gsap.fromTo(
    '.portfolio_section_top_row',
    { xPercent: 70 },
    {
      scrollTrigger: {
        trigger: '.portfolio_section',
        start: '200 bottom',
        end: 'bottom top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      xPercent: -20,
    }
  );
  gsap.fromTo(
    '.portfolio_section_bottom_row',
    { xPercent: -70 },
    {
      scrollTrigger: {
        trigger: '.portfolio_section',
        start: '200 bottom',
        end: 'bottom top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      xPercent: 20,
    }
  );

  const tlBall = gsap.timeline({
    scrollTrigger: {
      trigger: '.portfolio_section',
      start: 'top center',
      end: 'bottom center',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('portfolio');
      },
      onEnterBack: () => {
        setActiveSection('portfolio');
      },
    },
  });
  tlBall.fromTo(
    '.portfolio_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      duration: 1,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    }
  );

  tlBall.to('.portfolio_section_flying_ball', {
    duration: 3,
    y: () => boxHeight('.portfolio_section') - 300,
  });

  tlBall.to('.portfolio_section_flying_ball', {
    duration: 1,
    rotate: '+=360deg',
    opacity: 0,
    scale: 0,
  });
};


export const portfolioAnimationMV = () => {
  gsap.fromTo(
    '.portfolio_section_top_row',
    { xPercent: 70 },
    {
      scrollTrigger: {
        trigger: '.portfolio_section',
        start: '200 bottom',
        end: 'bottom top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      xPercent: -100,
    }
  );
  gsap.fromTo(
    '.portfolio_section_bottom_row',
    { xPercent: -70 },
    {
      scrollTrigger: {
        trigger: '.portfolio_section',
        start: '200 bottom',
        end: 'bottom top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      xPercent: 100,
    }
  );
};
