import gsap from 'gsap';

const boxHeight = () => {
  const boxHeight = document.querySelector('.testimonials_section')?.clientHeight;
  return boxHeight || 1;
};
export const testimonialsAnimation = (setActiveSection: (id: string | null) => void) => {
  const tlBall = gsap.timeline({
    scrollTrigger: {
      trigger: '.testimonials_section',
      start: 'top center',
      end: 'bottom center',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('testimonials');
      },
      onEnterBack: () => {
        setActiveSection('testimonials');
      },
    },
  });
  tlBall.fromTo(
    '.testimonials_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      duration: 1,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    }
  );

  tlBall.to('.testimonials_section_flying_ball', {
    duration: 3,
    y: () => boxHeight() - 300,
  });

  tlBall.to('.testimonials_section_flying_ball', {
    duration: 1,
    rotate: '+=360deg',
    opacity: 0,
    scale: 0,
  });
};
