import gsap from 'gsap';


export const processAnimation = (setActiveSection: (id: string | null) => void) => {
    gsap.timeline({
    scrollTrigger: {
      trigger: '.portfolio_section',
      start: 'top center',
      end: 'bottom center',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('process');
      },
      onEnterBack: () => {
        setActiveSection('process');
      },
    },
  });
  
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.process_section',
      start: 'center center',
      end: '1900 top',
      scrub: 0.7,
      pin: true,
      invalidateOnRefresh: true,
    },
  });
  tl.set(['.process_card_description_1'], { opacity: 1, bottom: '72%' });
  // 1 Process section
  tl.fromTo('.process_image_1', { opacity: 1 }, { opacity: 1, duration: 1.25 });
  tl.fromTo('.process_image_1', { opacity: 1 }, { opacity: 0, duration: 0.75 });
  tl.fromTo(
    '.process_card_1',
    { position: 'absolute', bottom: '72%' },
    {
      position: 'absolute',
      bottom: '72%',
    },
    '-=0.75'
  );
  tl.fromTo('.process_card_title_1', {}, { opacity: 0.5 }, '-=0.25');

  // 2 Process section
  tl.fromTo('.process_image_2', { opacity: 0 }, { opacity: 1, duration: 0.25 }, '-=0.5');
  tl.fromTo('.process_image_2', { opacity: 1 }, { opacity: 1, duration: 1 });
  tl.fromTo('.process_image_2', { opacity: 1 }, { opacity: 0, duration: 0.75 });
  tl.fromTo('.process_card_2', {}, { position: 'absolute', bottom: '66%' }, '-=2.75');
  tl.fromTo(
    '.process_card_description_1',
    { scale: 1 },
    { opacity: 0, scale: 0, duration: 0.5, transition: 'all 0.01s linear' },
    '-=2.75'
  );
  tl.fromTo('.process_card_description_2', { scale: 0 }, { opacity: 1, scale: 1 }, '-=2.75');
  tl.fromTo('.process_card_title_2', { opacity: 1 }, { opacity: 0.5 }, '-=0.25');

  // 3 Process section
  tl.fromTo('.process_image_3', { opacity: 0 }, { opacity: 1, duration: 0.25 }, '-=0.5');
  tl.fromTo('.process_image_3', { opacity: 1 }, { opacity: 1, duration: 1 });
  tl.fromTo('.process_image_3', { opacity: 1 }, { opacity: 0, duration: 0.75 });
  tl.fromTo('.process_card_3', {}, { position: 'absolute', bottom: '60%' }, '-=2.75');
  tl.fromTo(
    '.process_card_description_2',
    { scale: 1 },
    { opacity: 0, scale: 0, duration: 0.5, transition: 'all 0.01s linear' },
    '-=2.75'
  );
  tl.fromTo('.process_card_description_3', { scale: 0 }, { opacity: 1, scale: 1 }, '-=2.75');
  tl.fromTo('.process_card_title_3', { opacity: 1 }, { opacity: 0.5 }, '-=0.25');

  // 4 Process section
  tl.fromTo('.process_image_4', { opacity: 0 }, { opacity: 1, duration: 0.25 }, '-=0.5');
  tl.fromTo('.process_image_4', { opacity: 1 }, { opacity: 1, duration: 1 });
  tl.fromTo('.process_image_4', { opacity: 1 }, { opacity: 0, duration: 0.75 });
  tl.fromTo('.process_card_4', {}, { position: 'absolute', bottom: '54%' }, '-=2.75');
  tl.fromTo(
    '.process_card_description_3',
    { scale: 1 },
    { opacity: 0, scale: 0, duration: 0.5, transition: 'all 0.01s linear' },
    '-=2.75'
  );
  tl.fromTo('.process_card_description_4', { scale: 0 }, { opacity: 1, scale: 1 }, '-=2.75');
  tl.fromTo('.process_card_title_4', { opacity: 1 }, { opacity: 0.5 }, '-=0.25');

  // 5 Process section
  tl.fromTo('.process_image_5', { opacity: 0 }, { opacity: 1, duration: 0.25 }, '-=0.5');
  tl.fromTo('.process_image_5', { opacity: 1 }, { opacity: 1, duration: 1.75 });
  tl.fromTo('.process_card_5', {}, { position: 'absolute', bottom: '48%' }, '-=2.75');
  tl.fromTo(
    '.process_card_description_4',
    { scale: 1 },
    { opacity: 0, scale: 0, duration: 0.5, transition: 'all 0.01s linear' },
    '-=2.75'
  );
  tl.fromTo('.process_card_description_5', { scale: 0 }, { opacity: 1, scale: 1 }, '-=2.75');

  const tlBall = gsap.timeline({
    scrollTrigger: {
      trigger: '.process_section',
      start: 'center center',
      end: '1900 top',
      scrub: 1,
      invalidateOnRefresh: true,
    },
  });
  tlBall.fromTo(
    '.process_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      ease: 'none',
      duration: 1,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    }
  );

  tlBall.to('.process_section_flying_ball', {
    ease: 'none',
    duration: 3,
    y: () => {
      return 700;
    },
  });

  tl.to('.process_section_flying_ball', {
    duration: 1,
    ease: 'none',
    rotate: '+=360deg',
    opacity: 0,
    scale: 0,
  });
};
