import { Portfolio } from 'types';

import Accuscore from 'assets/main/porfolio/Accuscore.webp';
import CargoFlowers from 'assets/main/porfolio/CargoFlowers.webp';
import CleverWallet from 'assets/main/porfolio/CleverWallet.webp';
import EPICompliance from 'assets/main/porfolio/EPICompliance.webp';
import Epik from 'assets/main/porfolio/Epik.webp';
import EximFlowers from 'assets/main/porfolio/EximFlowers.webp';
import Fastr from 'assets/main/porfolio/Fastr.webp';
import Kolyom from 'assets/main/porfolio/Kolyom.webp';
import Locolabs from 'assets/main/porfolio/Locolabs.webp';
import MetaAds from 'assets/main/porfolio/MetaAds.webp';
import Orkistra from 'assets/main/porfolio/Orkistra.webp';
import Reconnect from 'assets/main/porfolio/Reconnect.webp';
import RegistrarSoftware from 'assets/main/porfolio/RegistrarSoftware.webp';
import Shopiview from 'assets/main/porfolio/Shopiview.webp';
import SwissBiometrix from 'assets/main/porfolio/SwissBiometrix.webp';
import Ticombo from 'assets/main/porfolio/Ticombo.webp';
import Tixnet from 'assets/main/porfolio/Tixnet.webp';
import TrustRatings from 'assets/main/porfolio/TrustRatings.webp';

export const portfolioTopRow: Portfolio[] = [
  {
    id: 1,
    title: 'Reconnect AI',
    description:
      'Reconnect AI creates interactive avatars from images or videos, allowing real-time communication with historical figures, celebrities, or loved ones. It supports 130+ languages and offers customizable AI-driven interactions',
    image: Reconnect,
  },
  {
    id: 2,
    title: 'Ticombo',
    description:
      'For Ticombo, we developed a transparent and user-friendly ticket marketplace. The platform supports multiple languages and currencies, offering customized features for local users',
    image: Ticombo,
  },
  {
    id: 3,
    title: 'Clever wallet',
    description:
      'Clever Wallet provides SDKs and APIs for building financial apps, supporting multi-currency wallets, transaction processing, and integrations with external payment systems',
    image: CleverWallet,
  },
  {
    id: 4,
    title: 'Meta Ads',
    description:
      'We built a complete marketing platform for Meta Ads, leveraging the Near blockchain. This innovative platform is designed for executing and analyzing marketing campaigns within the metaverse',
    image: MetaAds,
  },
  {
    id: 5,
    title: 'Tixnet',
    description:
      'We developed the Tixnet platform, a primary ticketing solution for event organizers. Tixnet stands out as the first global marketplace offering a free multilingual and multi-currency platform',
    image: Tixnet,
  },
  {
    id: 6,
    title: 'Shopiview',
    description:
      'We developed Shopiview, a startup platform that allows users to leave paid reviews on various shopping sites',
    image: Shopiview,
  },
];

export const portfolioBottomRow: Portfolio[] = [
  {
    id: 7,
    title: 'Accuscore',
    description:
      'Sports predictor. The system processes data, runs 10,000 simulations of sports events, and generates predictions. Features include fast data analysis and real-time updates',
    image: Accuscore,
  },
  {
    id: 8,
    title: 'Cargo flowers',
    description:
      'Mobile personal Account of the client of the service for the transportation of flowers around the world, where the user can independently check or track the delivery of goods online',
    image: CargoFlowers,
  },
  {
    id: 9,
    title: 'EPICompliance',
    description:
      'For EPICompliance, we created an online platform that simplifies HIPAA compliance for healthcare organizations',
    image: EPICompliance,
  },
  {
    id: 10,
    title: 'Trust Ratings',
    description:
      'We created a comprehensive system for TrustRatings, designed to collect and analyze domain data',
    image: TrustRatings,
  },
  {
    id: 11,
    title: 'Epik',
    description:
      'For Epik.com, a leading provider of domain management solutions, we developed an integrated full-service domain management system',
    image: Epik,
  },
  {
    id: 12,
    title: 'Locolabs',
    description:
      'For Loco Labs, we developed several custom solutions, including the Show Producer application. This tool enables local advertising campaigns across various user-owned devices',
    image: Locolabs,
  },
];

export const portfolioThirdRow: Portfolio[] = [
  {
    id: 13,
    title: 'Fastr',
    description:
      'Created to quickly develop a concept for investor negotiations, Fastr is a global data collection and analysis platform. Users can join via website or app, complete surveys, and earn rewards. Powered by Universe blockchain',
    image: Fastr,
  },
  {
    id: 14,
    title: 'Kolyom',
    description:
      'Kolyom is an Israeli platform for website promotion based on Big Data analysis. We developed tools for enhancing organic traffic, improving search engine rankings, and generating leads',
    image: Kolyom,
  },
  {
    id: 15,
    title: 'Swiss Biometrix',
    description:
      'Our team developed a distributed access control system for Swiss Bio Metrix, managing access to various territories, buildings, and premises',
    image: SwissBiometrix,
  },
  {
    id: 16,
    title: 'Orkistra',
    description:
      'A project management system that uniquely supports idea and initiative development. It enables project planning during client communication, creating user stories and tasks, and tracking progress via Agile',
    image: Orkistra,
  },
  {
    id: 17,
    title: 'Exim Flowers',
    description:
      'A project automating business processes for purchasing and delivering flowers from producers to customers. Includes API and mobile apps for real-time tracking from plantations to sellers',
    image: EximFlowers,
  },
  {
    id: 18,
    title: 'Registrar software',
    description:
      'A top-tier domain management system, known as the "Swiss bank of domains." It supports all TLDs, integrates with registrars, and offers web, WPS, mail hosting, domain parking, marketplace, SSL, and escrow services',
    image: RegistrarSoftware,
  },
];
