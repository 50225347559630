import { SxProps } from '@mui/material';

import PodcastHosting from 'assets/main/awards/PodcastHosting.gif';
import PodcastHostingStaticGif from 'assets/main/awards/PodcastHostingStatic.webp';
import SecondPlace from 'assets/main/awards/SecondPlace.webp';
import SupportingSustainability from 'assets/main/awards/SupportingSustainability.webp';
import ThirtyPlus from 'assets/main/awards/ThirtyPlus.webp';
import TopMetaverseInnovators from 'assets/main/awards/TopMetaverseInnovators.webp';
import TrendMonitoring from 'assets/main/awards/TrendMonitoring.webp';
import WinnersGears from 'assets/main/awards/WinnersGears.webp';
import WinnersMetaverse from 'assets/main/awards/WinnersMetaverse.webp';

export interface IAwards {
  id: number;
  title: string;
  description: string;
  image?: string;
  imageGifStatic?: string;
  json?: string;
  mainBoxSX?: SxProps;
  imageBoxSX?: SxProps;
  textBoxSX?: SxProps;
  jsonStyle?: SxProps;
  textSX?: SxProps;
  textTitleSX?: SxProps;
}

export const awards: IAwards[] = [
  {
    id: 1,
    title: 'Best Use of Technology',
    description: 'NEAR Blockchain Hackathon: Recognized for top blockchain implementation',
    json: 'BestUseOfTechnology.json',
    jsonStyle: {
      maxWidth: '415px',
      width: '90%',
      position: 'absolute',
      bottom: '-65px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 2,
    title: 'Top 100 Metaverse Innovators 2022',
    description: 'Ranked among top metaverse innovators',
    image: TopMetaverseInnovators,
    mainBoxSX: {
      flexDirection: 'column-reverse',
      maxWidth: { xs: '250px', md: '630px' },
      overflow: 'visible',
    },
    imageBoxSX: {
      maxWidth: { xs: '219px', md: '519px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      top: { xs: '16px', md: '-26px' },
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 3,
    title: '2nd place',
    description: 'NEAR Blockchain Hackathon: Second place for innovative NEAR solutions',
    image: SecondPlace,
    imageBoxSX: {
      maxWidth: { xs: '218px', md: '246px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '80px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 4,
    title: '24+ Events Annually',
    description: 'Participating in 24+ industry events yearly',
    mainBoxSX: {
      maxWidth: { xs: '250px', md: '630px' },
      height: { xs: '308px', md: '778px' },
    },
    json: 'EventsAnnually.json',
    jsonStyle: {
      maxWidth: { xs: '188px', md: '590px' },
      width: '100%',
      position: 'absolute',
      bottom: { xs: '10px', md: '24px' },
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 5,
    title: 'Winners',
    description: 'DApp Hack 1.0 Hackathon: Community support and expert mentorship',
    image: WinnersGears,
    imageBoxSX: {
      maxWidth: { xs: '192px', md: '274px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: { xs: '16px', md: '-30px' },
      left: '24px',
    },
    textBoxSX: { width: '100%' },
  },
  {
    id: 6,
    title: 'Winners',
    description: 'Intema Metaverse Accelerator 2023: Metaverse startup competition winner',
    image: WinnersMetaverse,
    imageBoxSX: {
      maxWidth: { xs: '218px', md: '242px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '81px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 7,
    title: 'Hackathons',
    description: 'Participated in over 30 hackathons focused on innovation',
    image: ThirtyPlus,
    imageBoxSX: {
      maxWidth: { xs: '218px', md: '246px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '80px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 8,
    title: 'Winners',
    description: 'Blockchain GOLOS Hackathon: Best decentralized tech integration',
    json: 'Winners.json',
    jsonStyle: {
      maxWidth: '275px',
      width: '100%',
      position: 'absolute',
      bottom: { xs: '-50px', md: '0px' },
      right: '0px',
    },
  },
  {
    id: 9,
    title: 'Trend Monitoring',
    description: 'Tracking blockchain and Web3 trends',
    image: TrendMonitoring,
    imageBoxSX: {
      maxWidth: { xs: '218px', md: '296px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 10,
    title: 'Supporting Sustainability',
    description: 'Headgehog Eco Dash: YouTube Video promoting eco-friendly projects',
    image: SupportingSustainability,
    mainBoxSX: {
      flexDirection: { xs: 'column', md: 'column-reverse' },
      maxWidth: { xs: '250px', md: '630px' },
      overflow: 'visible',
    },
    imageBoxSX: {
      maxWidth: '630px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '0px',
      borderRadius: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 11,
    title: 'Podcast Hosting',
    description: 'Hosting podcasts with industry leaders',
    image: PodcastHosting,
    imageGifStatic: PodcastHostingStaticGif,
    imageBoxSX: {
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '44px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
];
