import Lottie from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { Portfolio } from 'types';
import { preloadImages } from 'utils/preloadingImages';

import { Box, Typography } from '@mui/material';

import preloader from 'assets/main/services/popup/preloader.json';

interface IProps {
  portfolio: Portfolio;
}

const PortfolioCard: FC<IProps> = ({ portfolio }) => {
  const { title, description, image } = portfolio;
  const [shouldRenderImage, setShouldRenderImage] = useState(false);
  useEffect(() => {
    (async () => {
      await preloadImages([image]);
      setShouldRenderImage(true);
    })();
  });
  return (
    <Box
      sx={{
        height: {xs:'418px',md: '500px'},
        width: {xs:'250px',md: '410px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '16px',
      }}
    >
      {!shouldRenderImage ? (
        <Box
          sx={{
            width: {xs:'250px', md: '410px' },
            height: {xs:'250px', md: '360px'},
            backgroundColor: '#1B1B1B',
            borderRadius: '20px',
            border: '1px solid #FFFFFF80',
            position: 'relative',
          }}
        >
          <Lottie
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            animationData={preloader}
            autoplay={true}
            loop={true}
          />
        </Box>
      ) : (
        <Box
          component="img"
          src={image}
          alt="image"
          sx={{
            width: {xs:'250px', md: '410px' },
            height: 'auto',
            backgroundColor: '#1B1B1B',
            borderRadius: '20px',
            border: '1px solid #FFFFFF80',
          }}
        />
      )}
      <Box>
        <Typography
          sx={{
            fontSize: {xs:'20px',md: '24px' },
            fontWeight: 700,
            lineHeight: {xs:'30px',md: '32.78px' },
            textAlign: 'left',
            color: theme.palette.text.primary,
            mb: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PortfolioCard;
