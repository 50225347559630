import gsap from 'gsap';

export const dreamAnimation = () => {
  gsap.set('.dream_section_text', { color: 'gainsboro' });
  gsap.to('.dream_section_text', {
    scrollTrigger: {
      trigger: '.dream_section_text',
      start: '700 bottom',
      end: 'bottom center',
      toggleActions: 'play none none reverse',
      invalidateOnRefresh: true,
    },
    duration: 1,
    text: {
      value: 'Got a tech dream? We Can Develop IT!',
      oldClass: 'startText',
      newClass: 'endText',
    },
  });
};

export const dreamAnimationMV = () => {
  gsap.set('.dream_section_text', { color: 'gainsboro' });
  gsap.to('.dream_section_text', {
    scrollTrigger: {
      trigger: '.dream_section_text',
      start: '200 bottom',
      end: 'bottom center',
      toggleActions: 'play none none reverse',
      invalidateOnRefresh: true,
    },
    duration: 1,
    text: {
      value: 'Got a tech dream? We Can Develop IT!',
      oldClass: 'startText',
      newClass: 'endText',
    },
  });
};