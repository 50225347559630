import { useMediaSize } from 'hooks/useDeviceDetector';
import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { EffectCreative, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Testimonials } from 'types';



import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



import { Box } from '@mui/material';



import TestimonialsCard from '../TestimonialsCard';


interface IProps {
  setProgress: Dispatch<SetStateAction<number>>;
  testimonials: Testimonials[];
}

const TestimonialsCarousel: FC<IProps> = ({ setProgress, testimonials }) => {
  const { isDesktop } = useMediaSize();
  const swiperRef = useRef<any>(null);
  const handleSlideChange = () => {
    setProgress((swiperRef?.current?.swiper?.realIndex / (testimonials.length - 1)) * 100);
  };
  const handleCardClick = (index: number) => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      if (index === swiper.realIndex) {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }
    }
  };
  return (
    <Box sx={{ maxWidth: '1070px', width: '100%', height: '529px' }}>
      {!isDesktop ? (
        <Swiper
          key={1}
          modules={[Autoplay]}
          ref={swiperRef}
          speed={1000}
          loop={true}
          autoplay={{ delay: 3000 }}
          direction="horizontal"
          slidesPerView="auto"
          onSlideChange={handleSlideChange}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide
              style={{
                width: '359px',
                cursor: 'grab',
              }}
              key={testimonial.id}
            >
              <TestimonialsCard testimonials={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Swiper
          key={2}
          modules={[EffectCreative, Autoplay]}
          ref={swiperRef}
          speed={1000}
          loop={true}
          autoplay={{ delay: 3000 }}
          creativeEffect={{
            progressMultiplier: 1,
            shadowPerProgress: true,
            limitProgress: 1,
            perspective: true,
            prev: {
              translate: [0, 0, -400],
              opacity: 1,
              scale: 1.1,
            },
            next: {
              scale: 0.7,
              translate: ['100%', 0, 0],
            },
          }}
          direction="horizontal"
          effect="creative"
          slidesPerView="auto"
          spaceBetween={30}
          onSlideChange={handleSlideChange}
          style={{ maxWidth: '1070px', width: '100%', height: '529px' }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              style={{
                width: '520px',
                cursor: 'grab',
              }}
              onClick={() => {
                handleCardClick(index);
              }}
              key={testimonial.id}
            >
              <TestimonialsCard testimonials={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
};

export default TestimonialsCarousel;
