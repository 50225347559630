import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { useState } from 'react';
import { theme } from 'theme';
import { boxHeight } from 'utils/boxHeight';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import PortfolioCard from 'components/portfolio/PortfolioPageCard';

import { ReactComponent as Circlet } from 'assets/portfolio/Circlet.svg';
import { ReactComponent as OurWorks } from 'assets/portfolio/OurWorks.svg';
import { ReactComponent as Palette } from 'assets/portfolio/Palette.svg';
import { ReactComponent as Pen } from 'assets/portfolio/Pen.svg';

import { portfolioBottomRow, portfolioThirdRow, portfolioTopRow } from '../main/portfolio/consts';
import { portfolioPageAnimation } from './animation';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);
const PortfolioPage = () => {
  const [portfolioPortion, setPortfolioPortion] = useState<number>(1);
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1025px)', () => {
      portfolioPageAnimation(boxHeight('.portfolio_page_content'));
    });
  }, [boxHeight('.portfolio_page_content')]);
  return (
    <Box
      id="portfolio"
      sx={{
        minHeight: '100vh',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.black.main,
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '2016px',
          p: { xs: '114px 16px 100px 16px', md: '220px 24px 150px 24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <FlyingBall
          clsName="portfolio_page_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          bgClr={theme.palette.orange.main}
          sx={{ right: '-20px', top: '200px' }}
        />
        <OurWorks
          style={{
            position: 'absolute',
            top: '82px',
            right: '0px',
            maxWidth: '1445px',
            width: '100%',
            height: 'auto',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '889px',
            left: '0px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Circlet />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '3440px',
            right: '-63px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Palette />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '4457px',
            left: '0px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Pen />
        </Box>

        <Box
          className="portfolio_page_content"
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              mb: { xs: '32px', md: '54px' },
              color: theme.palette.text.primary,
            }}
          >
            Portfolio
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: { xs: '16px', md: '30px' },
              justifyContent: 'center',
            }}
          >
            {portfolioTopRow.map((card) => (
              <PortfolioCard key={card.id} portfolio={card} />
            ))}
            {portfolioPortion > 1 &&
              portfolioBottomRow.map((card) => <PortfolioCard key={card.id} portfolio={card} />)}
            {portfolioPortion > 1 &&
              portfolioThirdRow.map((card) => <PortfolioCard key={card.id} portfolio={card} />)}
          </Box>
          {portfolioPortion < 2 && (
            <CustomButton
              sx={{
                margin: 'auto',
                mt: '32px',
                maxWidth: { xs: '343px', md: '182px' },
                width: '100%',
                height: '56px',
                borderRadius: '30px',
                backgroundColor: theme.palette.orange.main,
                color: theme.palette.text.secondary,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              }}
              onClick={() => {
                setPortfolioPortion((prev) => prev + 1);
              }}
            >
              Load more
            </CustomButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioPage;
