import gsap from 'gsap';
import { theme } from 'theme';

const boxWidth = () => {
  const divWidth: number =
    document.querySelector('.howDoWeDo_section_full')?.clientWidth || window.innerWidth * 3;
  return divWidth > 4950 ? divWidth : 4950;
};
export const howDoWeDoAnimation = (setActiveSection: (id: string | null) => void) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: '.howDoWeDo_section',
      start: 'center center',
      end: '2500 top',
      scrub: 1,
      pin: true,
      invalidateOnRefresh: true,
    },
  });
  let tlHide = gsap.timeline({
    scrollTrigger: {
      trigger: '.howDoWeDo_section',
      start: 'top center',
      end: '3000 top',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('howdowedo');
      },
      onEnterBack: () => {
        setActiveSection('howdowedo');
      },
    },
  });
  tlHide.to('.howDoWeDo_section_hide', {
    width: ()=>window.innerWidth * 3,
    height: ()=>window.innerWidth * 3,
    duration: 1,
  });
  tlHide.to('.howDoWeDo_section_title', {
    color: theme.palette.orange.main,
    duration: 0.1,
  });
  tlHide.to(
    '.howDoWeDo_section_phrase',
    {
      color: theme.palette.orange.main,
      duration: 0.1,
    },
    '<'
  );
  tlHide.to(
    '.howDoWeDo_section_json',
    {
      opacity: 1,
      duration: 0.1,
    },
    '<'
  );
  tlHide.fromTo(
    '.howDoWeDo_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      duration: 0.5,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    },
    '<'
  );
  tlHide.to('.howDoWeDo_section_full', {
    duration: 4,
    x: () => -(boxWidth() - window.innerWidth),
    delay: 0.5,
  });
  tlHide.to(
    '.flying_dynamic_block',
    {
      duration: 2,
      rotate: '+=360deg',
    },
    '<0.5'
  );
  tlHide.to(
    '.howDoWeDo_section_flying_ball',
    {
      rotate: '+=360deg',
      opacity: 0,
      scale: 0,
      duration: 2,
    },
    '<2'
  );
  tlHide.to('.howDoWeDo_section_title', {
    color: theme.palette.text.primary,
    duration: 0.1,
  });
  tlHide.to(
    '.howDoWeDo_section_phrase',
    {
      color: theme.palette.text.primary,
      duration: 0.1,
    },
    '<'
  );
  tlHide.to('.howDoWeDo_section_json', {
    opacity: 0,
    duration: 0.1,
  });
  tlHide.to('.howDoWeDo_section_hide', {
    top: '100%',
    left: '100%',
    width: 0,
    height: 0,
    duration: 0.5,
  });
};
