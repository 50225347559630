import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { TextPlugin } from 'gsap/dist/TextPlugin';
import React from 'react';

import { Box } from '@mui/material';

import { useSectionContext } from 'components/common/AppBar/SectionContext';
import { commonAnimation } from './animation';
import AwardsSection from './awards';
import { awardsAnimation } from './awards/animation';
import { dreamAnimation, dreamAnimationMV } from './dream/animation';
import FirstSection from './first';
import { firstAnimation } from './first/animation';
import HowWeDoWeDoIt from './how-do-we-do';
import { howDoWeDoAnimation } from './how-do-we-do/animation';
import NewsSection from './news';
import { newsAnimation } from './news/animation';
import PortfolioSection from './portfolio';
import { portfolioAnimation, portfolioAnimationMV } from './portfolio/animation';
import ProcessSection from './process';
import { processAnimation } from './process/animation';
import Services from './services';
import { servicesAnimation, servicesAnimationMV } from './services/animation';
import TestimonialsSection from './testimonials';
import { testimonialsAnimation } from './testimonials/animation';
import WhoWeAre from './who-we-are';
import { whoWeAreAnimation, whoWeAreAnimationMV } from './who-we-are/animation';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(useGSAP);
const MainPage = () => {
  const { setActiveSection } = useSectionContext();
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1025px)', () => {
      commonAnimation();
      firstAnimation();
      servicesAnimation(setActiveSection);
      howDoWeDoAnimation(setActiveSection);
      processAnimation(setActiveSection);
      portfolioAnimation(setActiveSection);
      testimonialsAnimation(setActiveSection);
      whoWeAreAnimation(setActiveSection);
      awardsAnimation(setActiveSection);
      newsAnimation(setActiveSection);
      dreamAnimation();
    });
    mm.add('(max-width: 1024px)', () => {
      servicesAnimationMV();
      portfolioAnimationMV();
      whoWeAreAnimationMV();
      dreamAnimationMV();
    });
  });
  return (
    <Box>
      <FirstSection />
      <Services />
      <HowWeDoWeDoIt />
      <ProcessSection />
      <PortfolioSection />
      <TestimonialsSection />
      <WhoWeAre />
      <AwardsSection />
      <NewsSection />
    </Box>
  );
};

export default MainPage;
