import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';

interface FormContextType {
  isOpenGotAProjectDrawer: boolean;
  setIsOpenGotAProjectDrawer: Dispatch<SetStateAction<boolean>>;
}

const GotAProjectContext = createContext<FormContextType | undefined>(undefined);

export const GotAProjectProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpenGotAProjectDrawer, setIsOpenGotAProjectDrawer] = useState<boolean>(false);

  return (
    <GotAProjectContext.Provider value={{ isOpenGotAProjectDrawer, setIsOpenGotAProjectDrawer }}>
      {children}
    </GotAProjectContext.Provider>
  );
};

export const useGotAProject = () => {
  const context = useContext(GotAProjectContext);
  if (!context) {
    throw new Error('useGotAProject must be used within a GotAProjectProvider');
  }
  return context;
};
