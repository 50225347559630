import React from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import ProcessCard from 'components/main/process/ProcessCard';
import ProcessCarousel from 'components/main/process/ProcessCarousel';

import { ReactComponent as Mouse } from 'assets/main/process/Mouse.svg';
import { ReactComponent as Pliers } from 'assets/main/process/Pliers.svg';
import { ReactComponent as Process } from 'assets/main/process/Process.svg';

import { process } from './consts';

const ProcessSection = () => {
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      <Box
        id="process"
        className="process_section"
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          py: { xs: '100px', xxxl: '140px' },
          px: { xs: '16px', md: '75px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <FlyingBall
          clsName="process_section_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          sx={{ right: 0, top: 0 }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '42px', md: '0px' },
            left: { xs: '0px', md: '50%' },
            zIndex: -1,
          }}
        >
          <Process
            style={{
              maxWidth: '989px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '232px',
            left: '-94px',
            zIndex: -1,
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Pliers />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '86px',
            right: '0px',
            zIndex: -1,
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Mouse />
        </Box>
        <Box
          sx={{
            position: 'relative',
            maxWidth: '1290px',
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              mb: { xs: '32px', md: '54px' },
            }}
          >
            Process
          </Typography>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: { xs: 'none', md: 'flex' },
              gap: '30px',
              height: '650px',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'end',
                p: '24px',
                minWidth: '520px',
                flex: 0.39,
                borderRadius: '20px',
                backgroundColor: theme.palette.black.main,
                overflow: 'hidden',
                gap: '8px',
                boxSizing: 'border-box',
              }}
            >
              {process.map((process) => (
                <ProcessCard key={process.id} process={process} />
              ))}
            </Box>

            <Box
              sx={{
                height: '650px',
                display: 'flex',
                flex: 0.61,
                borderRadius: '24px',
                position: 'relative',
                backgroundColor: 'white',
              }}
            >
              {process.map((item) => (
                <Box
                  className={`process_image_${item.id}`}
                  key={item.id}
                  component="img"
                  src={item.image}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                    width: '101%',
                    height: 'auto',
                    opacity: item.id === 0 ? 1 : 0,
                    transition: 'all 0.25s linear',
                    borderRadius: '24px',
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: { xs: 'flex', md: 'none' },
              gap: '30px',
              height: '650px',
            }}
          >
            <ProcessCarousel process={process} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProcessSection;
