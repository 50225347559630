import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router-dom";
import router from "./pages";
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { GotAProjectProvider } from 'components/common/GotAProject/GotAProjectContext';
import { SectionProvider } from './components/common/AppBar/SectionContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <SectionProvider>
    <GotAProjectProvider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </GotAProjectProvider>
  </SectionProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
