import { FunctionComponent, SVGProps } from 'react';

import Construct from 'assets/main/process/Construct.webp';
import { ReactComponent as ConstructIcon } from 'assets/main/process/ConstructIcon.svg';
import Deploy from 'assets/main/process/Deploy.webp';
import { ReactComponent as DeployIcon } from 'assets/main/process/DeployIcon.svg';
import Design from 'assets/main/process/Design.webp';
import { ReactComponent as DesignIcon } from 'assets/main/process/DesignIcon.svg';
import Discover from 'assets/main/process/Discover.webp';
import { ReactComponent as DiscoverIcon } from 'assets/main/process/DiscoverIcon.svg';
import Invent from 'assets/main/process/Invent.webp';
import { ReactComponent as InventIcon } from 'assets/main/process/InventIcon.svg';

export interface IProcess {
  id: number;
  title: string;
  firstDescription: string;
  secondDescription: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  percent: number;
  image: string;
}

export const process: IProcess[] = [
  {
    id: 1,
    title: 'Discover',
    firstDescription: 'We start by analyzing the problem and its context to uncover key issues',
    secondDescription:
      'This allows us to define a clear task, project charter, and strategy for solving the problem',
    icon: DiscoverIcon,
    percent: 48,
    image: Discover,
  },
  {
    id: 2,
    title: 'Invent',
    firstDescription:
      'We create innovative solutions using industry best practices and cutting-edge technologies',
    secondDescription:
      'At this stage, we develop a clear problem description, technical requirements, and the scope of the product',
    icon: InventIcon,
    percent: 21,
    image: Invent,
  },
  {
    id: 3,
    title: 'Design',
    firstDescription: 'The design phase helps avoid errors and control costs',
    secondDescription:
      'We create product prototypes and hold workshops with stakeholders to ensure the solution meets all requirements',
    icon: DesignIcon,
    percent: 10,
    image: Design,
  },
  {
    id: 4,
    title: 'Construct',
    firstDescription:
      'With a well-defined scope and confirmed design, our experts begin product development',
    secondDescription:
      'We build a solid architecture that ensures high quality, reduces costs, and accelerates the product’s time to market. Security is audited throughout the process',
    icon: ConstructIcon,
    percent: -1,
    image: Construct,
  },
  {
    id: 5,
    title: 'Deploy',
    firstDescription:
      'We ensure a smooth product launch and integration into your business processes',
    secondDescription:
      'Penetration testing and security audits are performed. Your product is ready for use, fully secured, and integrated into your business',
    icon: DeployIcon,
    percent: -12,
    image: Deploy,
  },
];
