import React, { useState } from 'react';
import { theme } from 'theme';

import { Box, LinearProgress, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import SwiperCarousel from 'components/main/testimonials/TestimonialsCarousel';

import { ReactComponent as PenBG } from 'assets/main/testimonials/PenBG.svg';
import { ReactComponent as TestimonialsBG } from 'assets/main/testimonials/TestimonialsBG.svg';

import { testimonials } from './consts';

const TestimonialsSection = () => {
  const [progress, setProgress] = useState<number>(0);
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  return (
    <Box
      id="testimonials"
      className="testimonials_section"
      sx={{
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '1920px',
          width: '100%',
          p: { xs: '100px 16px 100px 16px', md: '140px 24px 0px 24px' },
          margin: 'auto',
          boxSizing: 'border-box',
          minHeight: '959px',
        }}
      >
        <FlyingBall
          clsName="testimonials_section_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          sx={{ right: 0, top: 0 }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '67px', md: '94px' },
            left: { xs: '24px', md: '20%' },
            zIndex: -1,
          }}
        >
          <TestimonialsBG
            style={{
              maxWidth: '1700px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '340px',
            left: '50px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <PenBG />
        </Box>
        <Box
          sx={{
            position: 'relative',
            maxWidth: '1290px',
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
            minHeight: '959px',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              mb: { xs: '32px', md: '54px' },
            }}
          >
            Testimonials
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: { xs: '0px',md:'32px'},
              alignItems: { xs: 'start', md: 'center' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box>
              <Box
                sx={{
                  minHeight: '208px',
                  width: { xs: '100%', md: '410px' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '20px', md: '32px' },
                    fontWeight: 700,
                    lineHeight: { xs: '30px', md: '48px' },
                    textAlign: 'left',
                    mb: '24px',
                  }}
                >
                  Our customers trust us
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 400,
                    lineHeight: { xs: '21px', md: '24px' },
                    textAlign: 'left',
                    mb: '16px',
                  }}
                >
                  At WecandevelopIT, we believe that{' '}
                  <span style={{ color: theme.palette.orange.main }}>trust is the foundation</span>{' '}
                  of every{' '}
                  <span style={{ color: theme.palette.orange.main }}>successful relationship</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                  }}
                >
                  Our customers’ satisfaction and confidence in our services drive everything we do.
                  Here’s why our customers trust us
                </Typography>
              </Box>
              <LinearProgress
                sx={{
                  display: { xs: 'none', md: 'block' },
                  mt: '10px',
                  height: '6px',
                  border: '1px solid #eacaa9',
                  backgroundColor: 'rgba(234,202,169,0.28)',
                  borderRadius: '20px',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#FF7A01',
                    borderRadius: '20px',
                  },
                }}
                variant="determinate"
                value={progress}
              />
            </Box>
            <SwiperCarousel testimonials={testimonials} setProgress={setProgress} />
            <LinearProgress
              sx={{
                width: '100%',
                display: { xs: 'block', md: 'none' },
                mt: '10px',
                height: '6px',
                border: '1px solid #eacaa9',
                backgroundColor: 'rgba(234,202,169,0.28)',
                borderRadius: '20px',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#FF7A01',
                  borderRadius: '20px',
                },
              }}
              variant="determinate"
              value={progress}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialsSection;
