import { FC, ReactNode } from 'react';

import { Box, Button as MuiButton, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  children?: string | ReactNode;
  type?: 'submit' | 'button' | 'reset';
  variant?: 'contained' | 'outlined' | 'text';
  disabled?: boolean;
  loading?: boolean;
}

export const CustomButton: FC<Props> = ({
  loading,
  type = 'button',
  children,
  variant = 'contained',
  disabled,
  size = 'medium',
  sx,
  ...rest
}) => {
  return (
    <MuiButton
      disabled={disabled || loading}
      type={type}
      variant={variant}
      size={size}
      disableRipple={variant === 'text'}
      sx={{ textTransform: 'none', display: 'flex', alignItems: 'center',...sx }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          height: '20px',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          gap: '13.6px',
        }}
      >
        {children}
      </Box>
    </MuiButton>
  );
};

