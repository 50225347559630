import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import { theme } from 'theme';

import { Box, Drawer, IconButton, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import { CustomCheckbox } from 'components/common/Checkbox';
import SendMessagePopup from 'components/common/SendMessagePopup';
import TextInput from 'components/common/TextInput';

import { ReactComponent as Close } from 'assets/common/Close.svg';

import GotAProjectSwiper from './GotAProjectSwiper';
import { subjects } from './consts';
import { CONTACT_SCHEMA } from './validation';

interface FormValues {
  name: string;
  email: string;
  phone: string;
  company?: string;
  country?: string;
  message: string;
  accept: boolean;
}

export interface IPopupInfo {
  open: boolean;
  title: string;
  description: string;
}

interface IProps {
  isOpenGotAProjectDrawer: boolean;
  setIsOpenGotAProjectDrawer: Dispatch<SetStateAction<boolean>>;
}

export const GotAProjectDrawer: FC<IProps> = ({
  isOpenGotAProjectDrawer,
  setIsOpenGotAProjectDrawer,
}) => {
  const [popupInfo, setPopupInfo] = useState<IPopupInfo>({
    open: false,
    title: '',
    description: '',
  });
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(CONTACT_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      company: '',
      country: '',
      accept: false,
    },
  });
  const onSubmit = async (contacts: FormValues) => {
    if (!turnstileToken) {
      console.error('Turnstile token is missing');
      setPopupInfo({
        open: true,
        title: 'Form not submitted',
        description: 'Please try again later',

      });
      return;
    }
    try {
      setIsDisabledButton(true);
      const dataToSend = {
        name: contacts.name,
        email: contacts.email,
        phone: contacts.phone,
        message: contacts.message,
        company: contacts?.company,
        country: contacts?.country,
      };
      const formData = new FormData();
      Object.keys(dataToSend).forEach((key) => {
        const value = dataToSend[key as keyof typeof dataToSend];
        if (typeof value !== 'undefined') {
          formData.append(key, value);
        }
      });
      formData.append('selectedSubjects', JSON.stringify(selectedSubjects));
      formData.append('cf-turnstile-response', turnstileToken);
      await axios.post('https://wecandevelopit.com/sendmail/sendemail.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsDisabledButton(false);
      reset();
      setSelectedSubjects([]);
      setPopupInfo({
        open: true,
        title: 'Your message has been successfully sent! ',
        description: 'We will contact you within a day',
      });
      reset();
      setSelectedSubjects([]);
    } catch (error: any) {
      setIsDisabledButton(false);
      if (axios.isAxiosError(error) && error?.response) {
        const messageError = error.response?.data?.message;
        if (messageError === 'Captcha not completed, please try again') {
          setTurnstileToken(null);
        } else {
          setPopupInfo({
            open: true,
            title: 'Form not submitted',
            description: messageError || 'Please try again later',
          });
        }
      } else {
        setPopupInfo({
          open: true,
          title: 'Form not submitted',
          description: 'Please try again later',
        });
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^+\d]/g, '').replace(/(?!^)\+/g, '');
    setValue('phone', filteredValue);
  };
  return (
    <Drawer
      open={isOpenGotAProjectDrawer}
      onClose={() => {
        setIsOpenGotAProjectDrawer(false);
        reset();
        setSelectedSubjects([]);
      }}
      anchor="right"
      sx={{
        '.MuiDrawer-paper': {
          maxWidth: { xs: '100vw', md: '608px' },
          width: '100%',
          backgroundColor: theme.palette.black.main,
          backgroundImage: 'none',
        },
      }}
    >
      <Box
        sx={{
          overflowX: 'hidden',
          position: 'relative',
          padding: { xs: '16px', md: '24px' },
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '48px' },
              fontWeight: 800,
              lineHeight: { xs: '49px', md: '65px' },
              textAlign: 'left',
            }}
          >
            Got a <span style={{ color: theme.palette.orange.main }}> project</span> ?
          </Typography>
          <IconButton
            edge="end"
            size="large"
            aria-label="close"
            onClick={() => {
              setIsOpenGotAProjectDrawer(false);
              reset();
            }}
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.text.primary,
              },
              '&:active': {
                backgroundColor: theme.palette.text.primary,
              },
            }}
          >
            <Close stroke={theme.palette.text.secondary} />
          </IconButton>
        </Box>

        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '27,32px',
            textAlign: 'left',
          }}
        >
          Share the details of your project – like scope, timeframes, or business challenges. Our
          team will thoroughly review the materials and respond to you promptly.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '27,32px',
              textAlign: 'left',
            }}
          >
            {"I'm interested in"}
          </Typography>
          <GotAProjectSwiper
            subjects={subjects}
            selectedSubjects={selectedSubjects}
            setSelectedSubjects={setSelectedSubjects}
          />
        </Box>

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'start' },
            gap: '16px',
            alignSelf: 'center',
          }}
        >
          <TextInput name="name" label="Full Name*" errors={errors} control={control} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
            }}
          >
            <TextInput name="email" label="E-mail*" errors={errors} control={control} />
            <TextInput
              name="phone"
              label="Phone*"
              errors={errors}
              control={control}
              onChangeHandler={handleChange}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
            }}
          >
            <TextInput name="company" label="Company" errors={errors} control={control} />
            <TextInput name="country" label="Country or City" errors={errors} control={control} />
          </Box>
          <TextInput name="message" label="Message*" errors={errors} control={control} multiline />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
            <CustomCheckbox
              name="accept"
              label="I accept the Terms"
              errors={errors}
              control={control}
            />
            <Turnstile
              sitekey="0x4AAAAAAAzip5694tx-guhL"
              onVerify={(token) => setTurnstileToken(token)}
              theme="light"
              size="invisible"
              action="submit"
            />
            <CustomButton
              sx={{
                alignSelf: 'center',
                margin: 'auto',
                width: '100%',
                height: '56px',
                borderRadius: '30px',
                backgroundColor: theme.palette.orange.main,
                color: theme.palette.text.secondary,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              }}
              type="submit"
              disabled={isDisabledButton}
            >
              Send message{' '}
            </CustomButton>
            <Typography
              sx={{
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
              }}
            >
              By pressing “Send” button you confirm that you have read and accept our
              <Link
                to="/privacy"
                style={{
                  textDecoration: 'none',
                  margin: '0 3px',
                  color: theme.palette.orange.main,
                  textDecorationColor: theme.palette.orange.main,
                }}
              >
                Privacy Policy
              </Link>
              and
              <Link
                to="/terms"
                style={{
                  textDecoration: 'none',
                  margin: '0 3px',
                  color: theme.palette.orange.main,
                  textDecorationColor: theme.palette.orange.main,
                }}
              >
                Terms & Conditions.
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: 'column', md: 'flex' },
              gap: '10px',
              justifyContent: 'space-between',
              width: '100%',
            }}
          ></Box>
        </Box>
        <SendMessagePopup
          open={popupInfo.open}
          title={popupInfo.title}
          description={popupInfo.description}
          onClose={setPopupInfo}
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
        />
      </Box>
    </Drawer>
  );
};
