import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import MainLayout from 'components/main-layout';

import { Error } from './error';
import MainPage from './main';
import NewsPage from './news';
import NewsDetails from './news/news-details';
import { NotFound } from './not-found';
import PortfolioPage from './portfolio';
import Root from './root';
import CookiesPolicyPage from './terms/cookie-policy';
import PrivacyPolicyPage from './terms/privacy-policy';
import TermsConditionsPage from './terms/terms-conditions';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<Error />}>
      <Route element={<MainLayout />}>
        <Route index element={<MainPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:id" element={<NewsDetails />} />
        <Route path="/cookies" element={<CookiesPolicyPage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsConditionsPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

export default router;
