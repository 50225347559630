import  {useEffect, useState } from 'react';

interface IProps {
  json?: string
  loadJson: (name: string)=>Promise<any>
}

const useJsonRepeatPlay= ({ json,loadJson }:IProps) => {

  const [animationData, setAnimationData] = useState<any>(null);
  const [isPlay, setIsPlay] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);

  const handleMouseEnter = () => {
    if (!hasPlayed) {
      setIsPlay(true);
      setHasPlayed(true);
    }
  };
  useEffect(() => {
    setIsPlay(false);
  }, [hasPlayed]);
  useEffect(() => {
    (async () => {
      if (json) {
        const module = await loadJson(json);
        setAnimationData(module.default);
      }
    })();
  }, [json]);
  return ({animationData,isPlay,setIsPlay,setHasPlayed,handleMouseEnter});
};

export default useJsonRepeatPlay;
