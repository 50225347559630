import gsap from 'gsap';

const distanceToBottom = () => {
  const childBox = document.querySelector('.first_section') as HTMLElement;
  const parentBox = document.querySelector('.first_section_cards') as HTMLElement;

  if (childBox && parentBox) {
    const childRect = childBox.getBoundingClientRect();
    const parentRect = parentBox.getBoundingClientRect();
    return parentRect.bottom - childRect.bottom;
  } else {
    return 100;
  }
};

export const firstAnimation = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.first_section',
      start: 'bottom bottom',
      end: 'bottom center',
      scrub: 1,
      invalidateOnRefresh: true,
    },
  });
  tl.to('.first_section_flying_ball', {
    y: () => -distanceToBottom() - 50,
    duration: 1,
  });
  tl.to('.first_section_flying_ball', {
    scale: 0.4,
    opacity: 0,
    rotate: '360deg',
    duration: 1,
  });
};
