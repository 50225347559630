import React, { Dispatch, FC, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Divider, Drawer, IconButton, Link as MuiLink, Typography } from '@mui/material';

import { ReactComponent as Close } from 'assets/common/Close.svg';

import { popupBurgerLinks, social } from '../consts';
import { policies } from '../consts';

interface IProps {
  setIsOpenNavDrawer: Dispatch<SetStateAction<boolean>>;
  isOpenNavDrawer: boolean;
  setShowAppBar: Dispatch<SetStateAction<boolean>>;
  activeSection: string | null;
  pathname: string;
}

export const PopupBurger: FC<IProps> = ({
  setIsOpenNavDrawer,
  isOpenNavDrawer,
  setShowAppBar,
  pathname,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <Drawer
      open={isOpenNavDrawer}
      onClose={() => setIsOpenNavDrawer(false)}
      anchor={'right'}
      sx={{
        '.MuiDrawer-paper': {
          width: '100vw !important',
          backgroundColor: theme.palette.black.main,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          p: '20px 28px',
          boxSizing: 'border-box',
          gap: '24px',
        }}
      >
        <IconButton
          edge="end"
          size="large"
          aria-label="close"
          onClick={() => {
            setIsOpenNavDrawer(false);
          }}
          sx={{
            alignSelf: 'end',
            width: '48px',
            height: '48px',
            backgroundColor: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.text.primary,
            },
            '&:active': {
              backgroundColor: theme.palette.text.primary,
            },
          }}
        >
          <Close stroke={theme.palette.text.secondary} />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '32px',
          }}
        >
          {popupBurgerLinks.map((item) => (
            <Typography
              sx={{
                color: theme.palette.text.primary,
                // (item.link === 'process' &&
                //   activeSection &&
                //   ['process', 'howdowedo'].includes(activeSection)) ||
                // (item.link === 'portfolio' && pathname === '/portfolio')
                //   ? theme.palette.orange.main
                //   : item.link === activeSection
                //     ? theme.palette.orange.main
                //     : theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              key={item.link}
              onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if (currentPath === '/') {
                  if (item.link === '') {
                    scrollHandler(e, 'home');
                  } else {
                    scrollHandler(e, item.link);
                    await new Promise((resolve) => setTimeout(resolve, 50));
                    setShowAppBar(false);
                  }
                } else {
                  navigate('/#' + item.link);
                }
                setIsOpenNavDrawer(false);
              }}
            >
              {item.title}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            mt: '8px',
          }}
        >
          {social.map((item) => (
            <MuiLink key={item.link} href={item.link} underline="none" target="_blank">
              <Box
                component="img"
                alt="yotube"
                src={item.icon}
                onClick={() => {
                  setShowAppBar(false);
                }}
              />
            </MuiLink>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'start',
          }}
        >
          {policies.map((item) => (
            <Typography
              onClick={() => {
                navigate(`${item.link}`);
                setIsOpenNavDrawer(false);
              }}
              key={item.link}
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '21px',
                textDecoration: 'none',
                color:
                  pathname === item.link ? theme.palette.orange.main : theme.palette.text.primary,
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              {item.title}
            </Typography>
          ))}
        </Box>
        <Divider
          sx={{
            width: '100%',
            borderColor: theme.palette.text.primary,
          }}
        />
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'center',
            width: '100%',
          }}
        >
          © 2024 WecandevelopIT. All rights reserved.
        </Typography>
      </Box>
    </Drawer>
  );
};
