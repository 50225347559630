import { Testimonials } from 'types';

import AMKhan from 'assets/main/testimonials/AMKhan.webp';
import CliveFlory from 'assets/main/testimonials/CliveFlory.webp';
import ErikMarcus from 'assets/main/testimonials/ErikMarcus.webp';
import GeorgeBudd from 'assets/main/testimonials/GeorgeBudd.webp';
import JessicaRobison from 'assets/main/testimonials/JessicaRobison.webp';
import JohnVachna from 'assets/main/testimonials/JohnVachna.webp';
import PatrickKennedy from 'assets/main/testimonials/PatrickKennedy.webp';
import PaulVolk from 'assets/main/testimonials/PaulVolk.webp';
import WendyXa from 'assets/main/testimonials/WendyXa.webp';
import YaroslavVolovoj from 'assets/main/testimonials/YaroslavVolovoj.webp';

export const testimonials: Testimonials[] = [
  {
    id: 1,
    name: 'George Budd',
    job: 'Chief Software Architect, GjBudd Designs LLC',
    position: 'Custom Software Development for Software Company',
    avatar: GeorgeBudd,
    bodyFirst:
      '“The team impressed us with their promptness and  competence in solving a wide variety of problems”',
    bodySecond:
      ' “We Can Develop It delivered the project on time and met all requirements. The  team quickly implemented changes, allowing for flexibility in response to customer  needs. Accessible via email and virtual meetings, they offer professional and  competent services”',
    rating: 5,
    createdAt: 'May 13, 2024',
    linkedin: 'https://www.linkedin.com/in/georgejbudd/',
  },
  {
    id: 2,
    name: 'Clive Flory',
    job: 'CEO, Ostiary',
    position: '',
    avatar: CliveFlory,
    bodyFirst: 'We Can Develop It has worked with us on several  projects over the past 15 months',
    bodySecond:
      '“Their work quality and  attention to detail, critical in software development, have been  exceptionally high. Vitaliy is an excellent IT team manager, and  I value his integrity and honesty throughout our collaborations.  I continue to partner with them on new projects”',
    rating: 5,
    createdAt: 'February 23, 2013',
    linkedin: 'https://www.linkedin.com/in/cliveflory/',
  },
  {
    id: 3,
    name: 'AM Khan',
    job: 'Owner, AM Khan',
    position: 'Automated Software Dev for Digital Marketing Agency',
    avatar: AMKhan,
    bodyFirst: '“They were perfect”',
    bodySecond:
      '“We Can Develop It successfully built a GUI and integrated an API into the solution. The team provided regular updates via email, meetings, and messaging apps, allowing for timely client feedback. Their efficient project management ensured positive outcomes”',
    rating: 5,
    createdAt: 'Mar 4, 2024',
    linkedin: 'https://www.linkedin.com/in/amkhangoogle/',
  },
  {
    id: 4,
    name: 'Paul Volk',
    job: 'CEO, Recomm',
    position: 'E-Commerce Dev & DevOps Services for AI-Driven Video Mktg Co',
    avatar: PaulVolk,
    bodyFirst: '“We were pleased with their individual approach and attention to detail”',
    bodySecond:
      '“We Can Develop It delivered the project on time, demonstrating quick responsiveness and employing agile methods like story points and user stories. Their personalized approach and meticulous attention to detail stood out throughout the project”',
    rating: 5,
    createdAt: 'Feb 27, 2024',
    linkedin: 'https://www.linkedin.com/in/paul-volk-pro/',
  },
  {
    id: 5,
    name: 'Erik Marcus',
    job: 'Fredrik Pemer, Founder & CTO',
    position: '',
    avatar: ErikMarcus,
    bodyFirst: 'We hired We Can Develop It for several web application development projects',
    bodySecond:
      '“Despite vague requirements, they delivered excellent results. I appreciate their commitment to unit test coverage, full feature delivery, and their patient, attentive customer service. I would gladly work with them again and highly recommend them as a reliable technology partner”',
    rating: 5,
    createdAt: 'February 23, 2013',
    linkedin: 'https://www.linkedin.com/in/marcuspemer/',
  },
  {
    id: 6,
    name: 'Patrick Kennedy',
    job: 'Epic HIM Instructional Designer (Consultant)',
    position: '',
    avatar: PatrickKennedy,
    bodyFirst: 'Vitaliy runs a professional software and web development company',
    bodySecond:
      '“They consistently delivered great results on time. I would hire his company again”',
    rating: 5,
    createdAt: 'February 23, 2013',
    linkedin: 'https://www.linkedin.com/in/patrickkennedy29/',
  },
  {
    id: 7,
    name: 'Jessica Robison',
    job: 'Special Education Paraprofessional',
    position: '',
    avatar: JessicaRobison,
    bodyFirst: 'We Can Develop It is a dedicated and professional team',
    bodySecond:
      '“Over the 12 years I’ve known them, I’ve seen how they consistently lead their projects with expertise and a commitment to growth. While working for a Domain Registrar, they assisted me numerous times with technical issues, helping me understand the functionality of the backend system they developed for us”',
    rating: 5,
    createdAt: 'June 2, 2023',
    linkedin: 'https://www.linkedin.com/in/jessica-robison-8458b6a6/',
  },

  {
    id: 8,
    name: 'John Vachna',
    job: 'COO, LeadWell Media',
    position: '',
    avatar: JohnVachna,
    bodyFirst: 'From offshore to IT, We Can Develop It has always delivered',
    bodySecond:
      "“Whenever I can't get something done, I turn to Vitaliy and his  team for all my programming and HTML needs. Thanks again  for all the help”",
    rating: 5,
    createdAt: 'Dec 27, 2006',
    linkedin: 'https://www.linkedin.com/in/johnvachna/',
  },
  {
    id: 9,
    name: 'Wendy Xa',
    job: 'Founder and Head of School, Terra Marin K-8 School and Terra Mandarin Preschool',
    position: '',
    avatar: WendyXa,
    bodyFirst: 'We Can Develop It is professional and hardworking',
    bodySecond:
      '“I highly recommend them to anyone looking for a talented web design team capable of following instructions and meeting project needs”',
    rating: 5,
    createdAt: 'April 22, 2013',
    linkedin: 'https://www.linkedin.com/in/wendy-xa-92893425/',
  },
  {
    id: 10,
    name: 'Yaroslav Volovoj ',
    job: 'CEO, MetaAds',
    position: 'Mktg Platform Dev & Ad Mgmt System for Metaverse Mktg Firm',
    avatar: YaroslavVolovoj,
    bodyFirst: '“I had a very pleasant experience”',
    bodySecond:
      ' “We Can Develop It successfully built the core platform functionality, delivered tasks on time, and tracked milestones efficiently. Their team was responsive, adaptable, and provided valuable alternative solutions, leveraging their expertise effectively”',
    rating: 5,
    createdAt: 'Feb 20, 2024',
    linkedin: 'https://www.linkedin.com/in/yaroslavvolovoj/',
  },
];
