import React, { Dispatch, FC, SetStateAction } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { theme } from 'theme';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Box, Typography } from '@mui/material';

import { ISubjects } from '../consts';

interface IProps {
  subjects: ISubjects[];
  selectedSubjects: string[];
  setSelectedSubjects: Dispatch<SetStateAction<string[]>>;
}

const GotAProjectSwiper: FC<IProps> = ({ subjects, selectedSubjects, setSelectedSubjects }) => {
  return (
    <Box sx={{width: '100%' }}>
      <Swiper modules={[Autoplay]} direction="horizontal" slidesPerView="auto">
        {subjects.map((tag) => (
          <SwiperSlide
            style={{
              width: 'auto',
              cursor: 'pointer',
            }}
            key={tag.id}
          >
            <Typography
              sx={{
                marginRight: '8px',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                textAlign: 'left',
                border: '1px solid #B0B5B7',
                borderRadius: '73px',
                p: '6px 16px',
                color: selectedSubjects.includes(tag.title)
                  ? theme.palette.text.secondary
                  : theme.palette.text.primary,
                backgroundColor: selectedSubjects.includes(tag.title)
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (selectedSubjects.includes(tag.title)) {
                  setSelectedSubjects((prev) => prev.filter((item) => item !== tag.title));
                } else {
                  setSelectedSubjects((prev) => [...prev, tag.title]);
                }
              }}
            >
              {tag.title}
            </Typography>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default GotAProjectSwiper;
