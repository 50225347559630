import gsap from 'gsap';

export const whoWeAreAnimation = (setActiveSection: (id: string | null) => void) => {
  const element = document.querySelector('.wwa_section_content');
  gsap.timeline({
    scrollTrigger: {
      trigger: '.wwa_section',
      start: 'top center',
      end: '3000 center',
      scrub: 1,
      invalidateOnRefresh: true,
      onEnter: () => {
        setActiveSection('whoweare');
      },
      onEnterBack: () => {
        setActiveSection('whoweare');
      },
    },
  });
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.wwa_section',
      start: 'center center',
      end: '2000 top',
      scrub: 1.5,
      pin: true,
      invalidateOnRefresh: true,
    },
  });
  tl.fromTo(
    '.wwa_section_photo',
    {
      x: 0,
    },
    {
      x: () => {
        return -(4440 - window.innerWidth * 0.85);
      },
    },
    0
  );
  tl.fromTo(
    '.wwa_section_text',
    {
      x: 0,
    },
    {
      x: () => {
        return -(3840 - window.innerWidth * 0.85);
      },
    },
    0
  );

  const tlBall = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: '.wwa_section_flying_ball',
      start: `400 center`,
      end: `1500 top`,
      scrub: 1,
      invalidateOnRefresh: true,
    },
  });
  tlBall.fromTo(
    '.wwa_section_flying_ball',
    {
      opacity: 0,
      scale: 0,
    },
    {
      duration: 1,
      opacity: 1,
      scale: 1,
      rotate: '360deg',
    }
  );
  tlBall.to('.flying_dynamic_block', {
    duration: 3,
    rotate: '+=360deg',
  });
  tlBall.to('.wwa_section_flying_ball', {
    duration: 1,
    rotate: '+=360deg',
    opacity: 0,
    scale: 0,
  });

  const tlHide = gsap.timeline({
    scrollTrigger: {
      trigger: '.wwa_section',
      start: 'center bottom',
      end: '1200 center',
      scrub: 1,
      invalidateOnRefresh: true,
    },
  });
  tlHide.fromTo(
    '.wwa_section_hide',
    {
      top: '100%',
      width: '300px',
      height: '300px',
    },
    {
      width: '400px',
      height: '400px',
      duration: 4,
      top: '90%',
      transition: '0.1s',
      onStart: () => {
        if (element instanceof HTMLElement) {
          element.style.opacity = '0';
        }
      },
    }
  );

  tlHide.to('.wwa_section_hide', {
    width: '600px',
    height: '600px',
    top: '80%',
    duration: 2,
    transition: '0.1s',
  });

  tlHide.to('.wwa_section_hide', {
    width: '700px',
    height: '700px',
    top: '60%',
    duration: 2,
    transition: '0.1s',
    onComplete: () => {
      if (element instanceof HTMLElement) {
        element.style.opacity = '1';
      }
    },
  });

  tlHide.to('.wwa_section_hide', {
    width: '1000px',
    height: '1000px',
    duration: 2,
    top: '50%',
    transition: '0.1s',
    onReverseComplete: () => {
      if (element instanceof HTMLElement) {
        element.style.opacity = '0';
      }
    },
  });

  tlHide.to('.wwa_section_hide', {
    width: '1500px',
    height: '1500px',
    transition: '0.1s',
    duration: 2,
  });
  tlHide.to('.wwa_section_hide', {
    duration: 2,
    width: () => {
      if (window.innerWidth > window.innerHeight) {
        return window.innerWidth * 1.5;
      } else return window.innerHeight * 1.5;
    },
    height: () => {
      if (window.innerWidth > window.innerHeight) {
        return window.innerWidth * 1.5;
      } else return window.innerHeight * 1.5;
    },
    transition: '0.1s',
  });
};

export const whoWeAreAnimationMV = () => {
  const element = document.querySelector('.wwa_section_content');
  if (element instanceof HTMLElement) {
    element.style.opacity = '1';
  }
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.wwa_section',
      start: 'center center',
      end: '2000 top',
      scrub: 1.5,
      pin: true,
      invalidateOnRefresh: true,
    },
  });
  tl.fromTo(
    '.wwa_section_photo',
    {
      x: 0,
    },
    {
      x: -(3740 - window.innerWidth),
    },
    0
  );
  tl.fromTo(
    '.wwa_section_text',
    {
      x: 0,
    },
    {
      x: -(3730 - window.innerWidth),
    },
    0
  );
};
