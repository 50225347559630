import { termsConditions } from './consts';
import React from 'react';

import { Box } from '@mui/material';

import TermsTemplate from 'components/common/TermsTemplate';

const TermsConditionsPage = () => {
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <TermsTemplate terms={termsConditions} />
    </Box>
  );
};

export default TermsConditionsPage;
